import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { useMotorsContext } from "../context/MotorsContextProvider";
import { Evaluation, Evaluations, Frame } from "../types";
import { z } from "zod";
import { ONE_MINUTE } from "@/utils/durationsInMilliseconds";

type DataSummaryGenericResponse = ThingworxError | DataSummaryGenericResult;

const CardData = z.object({
  motorId: z.number(),
  motorName: z.string(),
  description: z.string(),
  lastStatus: Evaluation,
  healthState: Evaluation,
  totalFrames: z.number(),
  evaluations: Evaluations,
});

export type CardData = z.infer<typeof CardData>;

const FrameData = z.object({
  motorName: z.string(),
  frameName: z.string(),
  frameId: z.number(),
  timestampStart: z.number(),
  timestampEnd: z.number(),
  status: Evaluation,
});

export type FrameData = z.infer<typeof FrameData>;

const SummaryData = z.object({
  cardData: z.array(CardData),
  frameData: z.array(FrameData),
});

export type SummaryData = z.infer<typeof SummaryData>;

const DataSummaryGenericResult = z.object({
  response: z.literal(true),
  data: SummaryData,
});

export type DataSummaryGenericResult = z.infer<typeof DataSummaryGenericResult>;

interface Payload {
  machineName: string;
  framesList: Frame[];
}

export const useGetDataSummaryGeneric = () => {
  const { machine } = useMachineContext();
  const { frames } = useMotorsContext();
  const { appKey } = useFirebaseContext();

  return useQuery<SummaryData>({
    queryKey: [
      "summary-data-generic",
      machine?.machine,
      JSON.stringify(frames),
    ],
    queryFn: async () => {
      const response = await FetchClient<Payload, DataSummaryGenericResponse>({
        appKey,
        payload: {
          machineName: machine?.machine || "",
          framesList: frames,
        },
        url: "dig.c.motorSummary_thing/Services/getDataSummaryGeneric",
      });

      if (!response.response) throw new Error(response.errorString);

      const validatedResponse = DataSummaryGenericResult.parse(response);
      return validatedResponse.data;
    },
    refetchInterval: ONE_MINUTE,
  });
};
