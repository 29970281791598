import { useGetAvailableNoveltyAndAnomalyAnalytics } from "../../api/useGetAvailableNoveltyAndAnomalyAnalytics";
import { AnalyticContainerSkeleton } from "@/features/Diagnostic/pages/LYO/components/AnalyticContainer/AnalyticContainerLayout";
import { Card } from "@/components/Layout/Card";
import { NoveltyAnomalyList } from "./NoveltyAnomalyList";
import { useTranslate } from "@/i18n/config";

interface NoveltyAnomalyAnalyticsSelectionProps {
  noveltyAnalytics: string[];
  setNoveltyAnalytics: React.Dispatch<React.SetStateAction<string[]>>;
  anomalyAnalytics: string[];
  setAnomalyAnalytics: React.Dispatch<React.SetStateAction<string[]>>;
}

export const NoveltyAnomalyAnalyticsSelection = ({
  noveltyAnalytics,
  anomalyAnalytics,
  setNoveltyAnalytics,
  setAnomalyAnalytics,
}: NoveltyAnomalyAnalyticsSelectionProps) => {
  const { data, isLoading, error } =
    useGetAvailableNoveltyAndAnomalyAnalytics();
  const translate = useTranslate();

  if (isLoading) {
    return <AnalyticContainerSkeleton sx={{ width: 320, height: 400 }} />;
  }

  if (error) {
    return <div>{translate("user_feedback.an_error_occurred")}</div>;
  }

  if (!data) {
    return <div>{translate("user_feedback.no_data")}</div>;
  }

  return (
    <Card sx={{ width: 320, height: 400, overflowY: "auto" }}>
      <NoveltyAnomalyList
        listName={translate("diagnostic.novelty_detection")}
        listItems={data.noveltyAnalytics}
        selectedAnalytics={noveltyAnalytics}
        setSelectedAnalytics={setNoveltyAnalytics}
      />
      <NoveltyAnomalyList
        listName={translate("diagnostic.anomaly_detection")}
        listItems={data.anomalyAnalytics}
        selectedAnalytics={anomalyAnalytics}
        setSelectedAnalytics={setAnomalyAnalytics}
      />
    </Card>
  );
};
