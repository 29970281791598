import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { useMotorsContext } from "../context/MotorsContextProvider";
import { ContextInfo, Frame } from "../types";
import { z } from "zod";

type DescriptiveDataResponse = ThingworxError | DescriptiveDataResult;

const DescriptiveData = z.object({
  analyticName: z.string(),
  motorName: z.string(),
  frameName: z.string(),
  data: z.array(z.array(z.number())),
  contextInfo: ContextInfo,
});

export type DescriptiveData = z.infer<typeof DescriptiveData>;

const DescriptiveDataResult = z.object({
  response: z.literal(true),
  data: z.array(DescriptiveData),
});

export type DescriptiveDataResult = z.infer<typeof DescriptiveDataResult>;

interface Payload {
  machineName: string;
  framesList: Frame[];
  motorsList: number[];
  analyticsList: string[];
}

export const useGetDescriptiveData = ({
  analytics,
}: {
  analytics: string[];
}) => {
  const { machine } = useMachineContext();
  const { frames, motors } = useMotorsContext();
  const { appKey } = useFirebaseContext();

  return useQuery<DescriptiveData[]>({
    queryKey: [
      "descriptive-data",
      machine?.machine,
      JSON.stringify(frames),
      JSON.stringify(motors),
      JSON.stringify(analytics),
    ],
    queryFn: async () => {
      const response = await FetchClient<Payload, DescriptiveDataResponse>({
        appKey,
        payload: {
          machineName: machine?.machine || "",
          framesList: frames,
          motorsList: motors,
          analyticsList: analytics,
        },
        url: "dig.c.motorDescriptive_thing/Services/getDescriptiveData",
      });

      if (!response.response) throw new Error(response.errorString);

      const validatedResponse = DescriptiveDataResult.parse(response);
      return validatedResponse.data;
    },
  });
};
