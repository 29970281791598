import { Card } from "@/components/Layout/Card";
import { AnalyticContainerSkeleton } from "@/features/Diagnostic/pages/LYO/components/AnalyticContainer/AnalyticContainerLayout";
import { Stack, Typography } from "@mui/material";
import { ResidualsChart } from "../components/Diagnostic/ResidualsChart";
import { useGetResiduals } from "../api/useGetResiduals";
import { useMotorsContext } from "../context/MotorsContextProvider";
import { useTranslate } from "@/i18n/config";

export const Decomposition = () => {
  const { data, isLoading, error } = useGetResiduals();
  const { frames, motors } = useMotorsContext();
  const translate = useTranslate();

  if (isLoading) {
    return <AnalyticContainerSkeleton />;
  }

  if (error) {
    return <div>{translate("user_feedback.an_error_occurred")}</div>;
  }

  if (!data) {
    return <div>{translate("user_feedback.no_data")}</div>;
  }

  return (
    <Stack gap={1}>
      {data.length > 0 ? (
        data.map((dataset) => (
          <ResidualsChart
            props={dataset}
            key={`${dataset.motorName}-${dataset.frameName}`}
          />
        ))
      ) : frames.length > 0 && motors.length > 0 ? (
        <Card sx={{ height: 400 }}>
          <Typography variant="h4">
            {translate("motors.no_data_try_another")}
          </Typography>
          <Typography variant="h6">
            {translate("motors.select_at_least")}
          </Typography>
        </Card>
      ) : (
        <Card sx={{ height: 400 }}>
          <Typography sx={{ color: "red" }} variant="h4">
            {translate("motors.no_selection")}
          </Typography>
          <Typography variant="h6">
            {translate("motors.select_at_least")}
          </Typography>
        </Card>
      )}
    </Stack>
  );
};
