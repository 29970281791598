/* eslint-disable @typescript-eslint/no-explicit-any */
import { PropsWithChildren, createContext, useContext } from "react";
import { useImmerReducer } from "use-immer";
import { LineConfig } from "../types";

type LineEditorState = {
  nodes: any[];
  edges: any[];
};

type Action =
  | {
      type: "add node";
      node: any;
    }
  | { type: "edit node"; node: any }
  | {
      type: "update nodes";
      nodes: any[];
    }
  | {
      type: "connect edge";
      edges: any;
    }
  | {
      type: "update edges";
      edges: any;
    }
  | { type: "remove edge"; edgeId: string };

const reducer = (draft: LineEditorState, action: Action) => {
  switch (action.type) {
    case "add node":
      draft.nodes.push(action.node);
      break;
    case "update nodes":
      draft.nodes = action.nodes;
      break;
    case "connect edge":
      draft.edges = action.edges;
      break;
    case "update edges":
      draft.edges = action.edges;
      break;
    case "remove edge":
      draft.edges = draft.edges.filter((edge) => edge.id !== action.edgeId);
      break;

    case "edit node": {
      const nodeIndex = draft.nodes.findIndex(
        (node) => node.id === action.node.id,
      );
      draft.nodes[nodeIndex] = action.node;
      break;
    }
    default:
      break;
  }
};

const LineEditorContext = createContext<LineEditorState | null>(null);

const LineEditorDispatch = createContext<React.Dispatch<Action> | null>(null);

export const LineEditorContextProvider = ({
  initialConfig = { nodes: [], edges: [] },
  children,
}: PropsWithChildren<{
  initialConfig?: Pick<LineConfig, "edges" | "nodes">;
}>) => {
  const [state, dispatch] = useImmerReducer<LineEditorState, Action>(reducer, {
    nodes: initialConfig.nodes,
    edges: initialConfig.edges,
  });

  return (
    <LineEditorContext.Provider value={state}>
      <LineEditorDispatch.Provider value={dispatch}>
        {children}
      </LineEditorDispatch.Provider>
    </LineEditorContext.Provider>
  );
};

export const useLineEditorContext = () => {
  const context = useContext(LineEditorContext);
  if (context === null) {
    throw new Error(
      "useLineEditorContext must be used within a LineEditorContextProvider",
    );
  }
  return context;
};

export const useLineEditorDispatch = () => {
  const context = useContext(LineEditorDispatch);
  if (context === null) {
    throw new Error(
      "useLineEditorDispatch must be used within a LineEditorContextProvider",
    );
  }
  return context;
};
