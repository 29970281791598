import { StatesLogData, useGetStatesLog } from "../api/useGetStatesLog";
import { LineStatesLog } from "./LineStatesLog";
import { SkeletonCard } from "@/components/Layout/SkeletonCard";
import { Card } from "@/components/Layout/Card";
import { useTranslate } from "@/i18n/config";

export const getDataBatches = (
  data: StatesLogData,
): { [key: string]: StatesLogData } => {
  const batches: { [key: string]: StatesLogData } = {};
  if (Object.keys(data).length === 0) return batches;
  const batchLength = 15000;
  let totalStates = 0;

  for (const array of Object.values(data)) {
    totalStates += array.length;
  }
  const numberOfBatches = Math.ceil(totalStates / batchLength);
  const benchmarkBatch = data[Object.keys(data)[0]];
  const benchmarkBatchLength = benchmarkBatch.length;
  const fragmentsLength = Math.round(
    benchmarkBatchLength / (numberOfBatches + 1),
  );
  if (Object.keys(benchmarkBatch).length === 0) return batches;
  const firstTimestamp = benchmarkBatch[0].start;
  const benchmarkTimestamps: number[] = [];
  for (let i = 1; i < numberOfBatches; i++) {
    benchmarkTimestamps.push(benchmarkBatch[fragmentsLength * i].start);
  }
  if (numberOfBatches === 1) {
    batches[new Date(firstTimestamp).toLocaleString()] = data;
    return batches;
  }

  benchmarkTimestamps.forEach((timestamp, index) => {
    const batchIndex = index + 1;
    const isFirst = index === 0;
    const isLast = batchIndex === benchmarkTimestamps.length;
    const prevTimestamp = benchmarkTimestamps[index - 1];

    const batchName = isFirst
      ? new Date(firstTimestamp).toLocaleString()
      : new Date(prevTimestamp).toLocaleString();
    batches[batchName] = {};
    if (isFirst) {
      Object.entries(data).forEach(([machine, states]) => {
        if (!Object.keys(batches[batchName]).includes(machine)) {
          batches[batchName][machine] = [];
        }
        const statesFragment = states.filter(
          (state) => state.start < timestamp,
        );
        batches[batchName][machine] = statesFragment;
      });
    } else {
      Object.entries(data).forEach(([machine, states]) => {
        if (!Object.keys(batches[batchName]).includes(machine)) {
          batches[batchName][machine] = [];
        }
        const statesFragment = states.filter(
          (state) => state.start > prevTimestamp && state.start < timestamp,
        );
        batches[batchName][machine] = statesFragment;
      });
    }
    if (isLast) {
      const name = new Date(timestamp).toLocaleString();
      batches[name] = {};
      Object.entries(data).forEach(([machine, states]) => {
        if (!Object.keys(batches[name]).includes(machine)) {
          batches[name][machine] = [];
        }
        const statesFragment = states.filter(
          (state) => state.start > timestamp,
        );
        batches[name][machine] = statesFragment;
      });
    }
  });

  return batches;
};

export const LineStatesLogHandler = ({
  line_id,
  view_mode,
  has_view_from_first_product,
}: {
  line_id: number;
  view_mode: "automatic" | "manual";
  has_view_from_first_product: boolean;
}) => {
  const translate = useTranslate();
  const { data, isLoading, error } = useGetStatesLog({
    line_id,
    view_mode,
    has_view_from_first_product,
  });

  if (isLoading) {
    return <SkeletonCard height={400} />;
  }

  if (error) {
    return <Card>{translate("user_feedback.an_error_occurred")}</Card>;
  }

  if (
    !data ||
    Object.keys(data).length === 0 ||
    Object.keys(Object.keys(data)[0]).length === 0
  ) {
    return <Card>{translate("user_feedback.no_data")}</Card>;
  }

  const batches = getDataBatches(data.data);

  if (
    Object.keys(batches).length === 0 ||
    Object.keys(Object.keys(batches)[0]).length === 0
  ) {
    return <Card>{translate("user_feedback.no_data")}</Card>;
  }

  return <LineStatesLog dataBatches={batches} order={data.order || []} />;
};
