import { useEffect } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  OutlinedInput,
  Select,
} from "@mui/material";
import { FrameSelectGroup } from "./FrameSelectGroup";
import {
  useMotorsContext,
  useMotorsDispatchContext,
} from "../context/MotorsContextProvider";
import { useGetFrames } from "../api/useGetFrames";
import { FrameSelectGroupLarge } from "./FrameSelectGroupLarge";
import { Frame } from "../types";
import { useTranslate } from "@/i18n/config";

const parseData = (obj: { [key: string]: Frame[] }) => {
  const modifiedObj: { [key: string]: Frame[] } = {};

  for (const key in obj) {
    const strArr = key.split(" - ");
    const timestamp = strArr[strArr.length - 1];
    const dateString = new Date(parseInt(timestamp)).toLocaleString("en-US");
    const modifiedKey = key.replace(timestamp, dateString);
    modifiedObj[modifiedKey] = obj[key];
  }
  return modifiedObj;
};

export const FramesSelect = () => {
  const { frames } = useMotorsContext();
  const dispatch = useMotorsDispatchContext();
  const { data, isLoading, error } = useGetFrames();
  const translate = useTranslate();

  useEffect(() => {
    dispatch({ type: "CLEAR_FRAMES" });

    return () => {
      dispatch({ type: "CLEAR_FRAMES" });
    };
  }, [dispatch]);

  if (isLoading) {
    return (
      <FormControl sx={{ width: "140px" }} size="small">
        <InputLabel id="nested-frames-select-label">
          {translate("frame_other")}
        </InputLabel>
        <Select
          labelId={`nested-frames-select-label`}
          id={`nested-frames-select`}
          multiple
          value={[]}
          input={<OutlinedInput label={translate("frame_other")} />}
        ></Select>
      </FormControl>
    );
  }

  if (error) {
    return <div>{translate("user_feedback.an_error_occurred")}</div>;
  }

  if (!data) {
    return <div>{translate("user_feedback.no_data")}</div>;
  }
  const formattedData = parseData(data);

  return (
    <FormControl sx={{ width: "140px" }} size="small">
      <InputLabel id="nested-frames-select-label">
        {translate("frame_other")}
      </InputLabel>
      <Select
        labelId="nested-frames-select-label"
        id="nested-frames-select"
        multiple
        value={frames}
        input={<OutlinedInput label={translate("frame_other")} />}
        renderValue={(selected) => `Selected: ${selected.length}`}
      >
        <Box sx={{ maxHeight: "50vh" }}>
          {Object.entries(formattedData).map(([key, items]) => {
            return items.length > 10 ? (
              <FrameSelectGroupLarge
                key={key}
                parentName={key}
                children={items}
              />
            ) : (
              <FrameSelectGroup key={key} parentName={key} children={items} />
            );
          })}
        </Box>
      </Select>
    </FormControl>
  );
};
