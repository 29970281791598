import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { Item, useMotorsContext } from "../context/MotorsContextProvider";
import { z } from "zod";
import { Frame } from "../types";

type FramesResponse = ThingworxError | FramesResult;

const FramesResult = z.object({
  response: z.literal(true),
  list: z.record(z.array(Frame)),
});

export type FramesResult = z.infer<typeof FramesResult>;

interface Payload {
  machineName: string;
  timeSelectionList: Item[];
}

export const useGetFrames = () => {
  const { machine } = useMachineContext();
  const { timelapse, recipesFavorites, batchesFavorites } = useMotorsContext();

  const { appKey } = useFirebaseContext();

  return useQuery<{ [key: string]: Frame[] }>({
    queryKey: [
      "frames",
      machine?.machine,
      timelapse,
      JSON.stringify(recipesFavorites),
      JSON.stringify(batchesFavorites),
    ],
    queryFn: async () => {
      const response = await FetchClient<Payload, FramesResponse>({
        appKey,
        payload: {
          machineName: machine?.machine || "",
          timeSelectionList:
            timelapse === "batches" ? batchesFavorites : recipesFavorites,
        },
        url: "dig.c.motorUtility_thing/Services/getFrames",
      });

      if (!response.response) throw new Error(response.errorString);

      const validatedResponse = FramesResult.parse(response);
      return validatedResponse.list;
    },
  });
};
