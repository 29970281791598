import { useTimeSelection } from "@/store/useTimeSelection";
import { Button } from "@mui/material";
import { DateTime } from "luxon";

export const MoveDateButton = ({
  action,
  text,
}: {
  action: "nextDay" | "prevDay";
  text: string;
}) => {
  const { dates } = useTimeSelection();

  const { dateStart, dateEnd } = dates;
  return (
    <Button
      variant="outlined"
      disabled={
        action === "nextDay"
          ? DateTime.now().month === dateEnd.month &&
            DateTime.now().day === dateEnd.day
          : false
      }
      onClick={() => {
        if (action === "nextDay") {
          const newDateStart = dateStart.plus({ days: 1 });
          const newDateEnd = dateEnd.plus({ days: 1 });
          useTimeSelection.setState((prevState) => ({
            ...prevState,
            dates: {
              dateStart: newDateStart,
              dateEnd: newDateEnd,
            },
          }));
        } else {
          const newDateStart = dateStart.minus({ days: 1 });
          const newDateEnd = dateEnd.minus({ days: 1 });
          useTimeSelection.setState((prevState) => ({
            ...prevState,
            dates: {
              dateStart: newDateStart,
              dateEnd: newDateEnd,
            },
          }));
        }
      }}
    >
      {text}
    </Button>
  );
};
