import { useTranslate } from "@/i18n/config";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { SkeletonCard } from "@/components/Layout/SkeletonCard";
import { XRangeChart } from "@/components/highcharts/xrange/XRange";
import { Card } from "@/components/Layout/Card";
import { useGetSingleImputationStates } from "@/features/StopJustification/api/useGetSingleImputationStates";
import { parseStatesLogData } from "@/features/StopJustification/utils/parseStatesLogData";

export const RunStatesModal = ({
  dateStart,
  dateEnd,
  isOpen,
  close,
}: {
  dateStart: number;
  dateEnd: number;
  isOpen: boolean;
  close: () => void;
}) => {
  const translate = useTranslate();
  const { data, isLoading, error } = useGetSingleImputationStates({
    dateStart,
    dateEnd,
  });

  if (isLoading)
    return (
      <Dialog onClose={close} open={isOpen} maxWidth="lg" fullWidth>
        <DialogTitle>{translate("acopos.run_states_detail")}</DialogTitle>
        <DialogContent>
          <SkeletonCard height={400} />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={close}>
            {translate("actions.back")}
          </Button>
        </DialogActions>
      </Dialog>
    );

  if (error)
    return (
      <Dialog onClose={close} open={isOpen} maxWidth="lg" fullWidth>
        <DialogTitle>{translate("acopos.run_states_detail")}</DialogTitle>
        <DialogContent>
          <Card sx={{ height: 400 }}>
            {translate("user_feedback.an_error_occurred")}
          </Card>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={close}>
            {translate("actions.back")}
          </Button>
        </DialogActions>
      </Dialog>
    );

  if (!data)
    return (
      <Dialog onClose={close} open={isOpen} maxWidth="lg" fullWidth>
        <DialogTitle>{translate("acopos.run_states_detail")}</DialogTitle>
        <DialogContent>
          <Card sx={{ height: 400 }}>{translate("user_feedback.no_data")}</Card>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={close}>
            {translate("actions.back")}
          </Button>
        </DialogActions>
      </Dialog>
    );

  const { categories, chartData } = parseStatesLogData(data.data);

  return (
    <Dialog onClose={close} open={isOpen} maxWidth="lg" fullWidth>
      <DialogTitle>{translate("acopos.run_states_detail")}</DialogTitle>
      <DialogContent>
        <Card>
          <XRangeChart.Custom
            categories={categories}
            data={chartData}
            title=""
          />
        </Card>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={close}>
          {translate("actions.back")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
