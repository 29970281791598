import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useTimeSelection } from "@/store/useTimeSelection";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { z } from "zod";

export type StateLogResponse = ThingworxError | StateLogResult;

const ArrayFilteredProps = z.object({
  duration: z.number(),
  stateColor: z.string(),
  timestampAfter: z.number(),
  errorDescription: z.string(),
  recipe: z.string(),
  stateString: z.string(),
  error: z.union([z.number(), z.string()]),
  timestamp: z.number(),
});

export type ArrayFilteredProps = z.infer<typeof ArrayFilteredProps>;

export const StateLogArrayProps = z.object({
  color: z.string(),
  end: z.number(),
  start: z.number(),
  state: z.string(),
});

export type StateLogArrayProps = z.infer<typeof StateLogArrayProps>;

const StateLogResult = z.object({
  stateLogArray: z.array(StateLogArrayProps),
  arrayFiltered: z.array(ArrayFilteredProps),
  statesList: z.array(z.string()),
  statesOccurred: z.array(z.string()),
  response: z.literal(true),
});

export type StateLogResult = z.infer<typeof StateLogResult>;

export const useGetStateLogData = () => {
  const { machine } = useMachineContext();
  const { appKey } = useFirebaseContext();
  const { dates } = useTimeSelection();

  return useQuery<StateLogResult>({
    queryKey: [
      "state-log-data",
      machine!.machine,
      dates.dateEnd,
      dates.dateStart,
    ],
    queryFn: async () => {
      const response = await FetchClient<
        {
          machineName: string;
          statesList: string[];
          dateStart: number;
          dateEnd: number;
        },
        StateLogResponse
      >({
        appKey,
        payload: {
          machineName: machine!.machine,
          statesList: [],
          // dateStart: dates.dateStart.startOf("day").toMillis(),
          // dateEnd: dates.dateEnd.endOf("day").toMillis(),
          dateStart: dates.dateStart.toMillis(),
          dateEnd: dates.dateEnd.toMillis(),
        },
        url: "dig.c.stateLog_thing/Services/getDataReact",
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      const validatedResponse = StateLogResult.parse(response);

      return validatedResponse;
    },
  });
};
