import { Card } from "@/components/Layout/Card";
import { Box, Stack, Chip, CircularProgress, Tooltip } from "@mui/material";
import { BASE_VERTICAL_DIMENSION } from "../config";
import {
  MachineNodeData,
  useGetMachineData,
} from "../Pages/LineOverview/api/useGetMachineData";
import { ResponsiveBar } from "@nivo/bar";
import { useMachineContext } from "@/context/machine-context";
import { useNavigate } from "react-router-dom";
import { useGetLandingData } from "@/features/LandingPage/api/useGetLandingData";
import { PlaceholderMachineNode } from "../Pages/Edit/custom-nodes/view/PlaceholderNode";
import { useGetHistoricMachineData } from "../Pages/HistoricLineDetail/api/useGetHistoricMachineData";
import { useTranslate } from "@/i18n/config";

/**
 * utile per renderizzare i nodi nella pagina di LineOverview
 */
export const CategoryColor = {
  Disconnected: { textColor: "#000", bgColor: "#eeeeee" },
  Producing: { textColor: "#fff", bgColor: "#0ab126" },
  "Not Producing": { textColor: "#fff", bgColor: "#ff7a0d" },
  Line: { textColor: "#fff", bgColor: "#1a98c9" },
  "Setup Pre-Production": { textColor: "#fff", bgColor: "#B277E2" },
  "Intentional No Production": { textColor: "#fff", bgColor: "#8a8a8a" },
} as const;

export type MachineNodeMapData = {
  enabled: boolean;
  isFirst?: boolean;
  isLast?: boolean;
  machine: string;
  machineType: string;
  machineModel: string;
};

export function MachineNodeDataLoader({ name }: { name: string }) {
  const { data: landingUserData, isLoading: loadingLandingUserData } =
    useGetLandingData();
  const { isLoading, data } = useGetMachineData({ machine: name });
  const { setMachine } = useMachineContext();
  const navigate = useNavigate();

  if (isLoading || loadingLandingUserData)
    return (
      <Card
        sx={{
          borderRadius: "4px",
          overflow: "hidden",
          height: BASE_VERTICAL_DIMENSION,
        }}
      >
        <Stack
          gap={2}
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="100%"
        >
          <span
            style={{
              display: "block",
              textAlign: "center",
              fontSize: ".75rem",
            }}
          >
            {name}
          </span>
          <CircularProgress />
        </Stack>
      </Card>
    );

  if (!data) return null;

  const userHasMachine = !!landingUserData?.landingPageData?.machineArray?.find(
    (machine) => machine.machine === name,
  );

  if (!userHasMachine) {
    return <PlaceholderMachineNode model={name} />;
  }

  return (
    <MachineNodeView
      data={data}
      machineName={name}
      onClick={() => {
        setMachine({
          machine: name,
          machineType: data.machine_type,
          machineLogic: data.machineLogic,
          hasBatch: data.hasBatch,
          hasSpeed: data.hasSpeed,
        });
        navigate("/machine-detail");
      }}
    />
  );
}

export function HistoricMachineNodeDataLoader({ name }: { name: string }) {
  const { data: landingUserData, isLoading: loadingLandingUserData } =
    useGetLandingData();
  const { isLoading, data } = useGetHistoricMachineData({ machine: name });
  const { setMachine } = useMachineContext();
  const navigate = useNavigate();

  if (isLoading || loadingLandingUserData)
    return (
      <Card
        sx={{
          borderRadius: "4px",
          overflow: "hidden",
          height: BASE_VERTICAL_DIMENSION,
        }}
      >
        <Stack
          gap={2}
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="100%"
        >
          <span
            style={{
              display: "block",
              textAlign: "center",
              fontSize: ".75rem",
            }}
          >
            {name}
          </span>
          <CircularProgress />
        </Stack>
      </Card>
    );

  if (!data) return null;

  const userHasMachine = !!landingUserData?.landingPageData?.machineArray?.find(
    (machine) => machine.machine === name,
  );

  if (!userHasMachine) {
    return <PlaceholderMachineNode model={name} />;
  }

  return (
    <MachineNodeView
      data={data}
      machineName={name}
      onClick={() => {
        setMachine({
          machine: name,
          machineType: data.machine_type,
          machineLogic: data.machineLogic,
          hasBatch: data.hasBatch,
          hasSpeed: data.hasSpeed,
        });
        navigate("/machine-detail");
      }}
    />
  );
}

export function MachineNodeView({
  data,
  machineName,
  onClick,
}: {
  data: MachineNodeData;
  machineName: string;
  onClick?: () => void;
}) {
  const translate = useTranslate();
  const {
    category,
    oee,
    state,
    extraCapacity,
    lineLosses,
    machineLosses,
    processLosses,
    label,
  } = data;

  return (
    <Stack
      onClick={onClick}
      sx={{
        fontSize: ".8rem",
        transition: "all 0.2s ease-in-out",
        cursor: "pointer",
        backgroundColor: data.state_color ?? CategoryColor[category].bgColor,
        borderRadius: "4px",
        overflow: "hidden",
      }}
      border={`2px solid ${
        data.state_color ?? CategoryColor[category].bgColor
      } `}
      height={"100%"}
    >
      <Box
        flex={1}
        sx={{
          transition: "all 0.2s ease-in-out",
          padding: ".5rem",
          backgroundColor: data.state_color ?? CategoryColor[category].bgColor,
          color: CategoryColor[category].textColor,
        }}
      >
        <Box>
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              fontWeight: "bold",
            }}
          >
            <Chip
              sx={{ color: "inherit", fontWeight: "bold" }}
              size="small"
              label={machineName}
            />
            {data.alarm_code ? (
              <Tooltip
                title={data.alarm_description ?? ""}
                arrow
                placement="bottom"
              >
                <Box sx={{ color: "inherit", fontWeight: "bold" }}>
                  {translate("error_code")}: {data.alarm_code}
                </Box>
              </Tooltip>
            ) : null}
          </Stack>
        </Box>
      </Box>

      <Box
        sx={{
          backgroundColor: "#444D5A",
          width: "100%",
          borderRadius: "4px",
          padding: ".5rem",
          flex: 1,
        }}
      >
        <Box
          sx={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            fontWeight: "bold",
          }}
          minHeight="1ch"
        >
          {label}
        </Box>

        <Stack
          sx={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            fontWeight: "bold",
          }}
          justifyContent="space-between"
          direction="row"
        >
          <Box>{state}</Box>

          {data.rejected_products > 0 ? (
            <Box>
              {translate("waste_other")}: {data.rejected_products}
            </Box>
          ) : null}
        </Stack>

        <Stack
          sx={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            fontWeight: "bold",
          }}
          justifyContent="space-between"
          direction="row"
        >
          <Box>
            {translate("kpi.oee")}:{" "}
            {oee !== undefined
              ? `${oee.toFixed(2)}%`
              : translate("not_available")}
          </Box>
          {/* todo: aggiungere prodotti */}
          {data.products > 0 ? (
            <Box>
              {translate("machine.products")}: {data.products}
            </Box>
          ) : null}
        </Stack>

        <Box sx={{ height: 12 }}>
          {oee !== undefined ? (
            <NivoBarChart
              values={{
                extraCapacity,
                lineLosses,
                machineLosses,
                oee,
                processLosses,
              }}
            />
          ) : null}
        </Box>
      </Box>
    </Stack>
  );
}

function NivoBarChart({
  values,
}: {
  values: {
    oee: number;
    lineLosses: number;
    processLosses: number;
    machineLosses: number;
    extraCapacity: number;
  };
}) {
  return (
    <ResponsiveBar
      data={[values]}
      keys={[
        "oee",
        "lineLosses",
        "processLosses",
        "machineLosses",
        "extraCapacity",
      ]}
      layout="horizontal"
      colors={["#76A12B", "#16A99C", "#ED8526", "#D62422", "#596679"]}
      enableGridY={false}
      enableGridX={false}
      enableLabel={false}
      valueFormat={(v) => `${v.toFixed(2)}%`}
      tooltip={({ id, value, color }) => (
        <Card
          style={{
            padding: 12,
            color,
            background: "#222222",
            display: "flex",
            gap: 8,
          }}
        >
          <span
            dangerouslySetInnerHTML={{
              __html: "&#x25A0;",
            }}
            style={{
              color,
              fontSize: 12,
            }}
          />
          <strong>
            {id}: {value.toFixed(2)}%
          </strong>
        </Card>
      )}
    />
  );
}
