import { Button } from "@mui/material";
import { useMotorsDispatchContext } from "../context/MotorsContextProvider";
import { useTranslate } from "@/i18n/config";

export const LiveButton = () => {
  const dispatch = useMotorsDispatchContext();

  const translate = useTranslate();

  return (
    <Button
      variant="outlined"
      onClick={() => {
        dispatch({ type: "CLEAR_FRAMES" });
      }}
    >
      {translate("live")}
    </Button>
  );
};
