import {
  SvgIconComponent,
  PieChartOutlineOutlined,
  WarningAmberRounded,
  HomeOutlined,
  RuleOutlined,
  FeedOutlined,
  PsychologyAltOutlined,
  ThermostatOutlined,
  MeetingRoomOutlined,
  TrackChangesOutlined,
  BallotOutlined,
  ElectricMeterOutlined,
  Inventory2Outlined,
  ChangeCircleOutlined,
  AlignHorizontalLeftOutlined,
  InsightsOutlined,
  PlaceOutlined,
  BuildOutlined,
  LeaderboardOutlined,
  FileDownloadOutlined,
  InventoryOutlined,
  AccountTreeOutlined,
  TuneOutlined,
  AodOutlined,
  DashboardCustomize,
  EditCalendarOutlined,
  BrowserUpdatedOutlined,
  PhonelinkEraseOutlined,
  Event,
  ForumOutlined,
} from "@mui/icons-material";
import { IconProps } from "../types";
import SpeedOutlined from "@mui/icons-material/SpeedOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
export interface NavigationLink {
  text: string;
  to: string;
  icon: SvgIconComponent | React.FunctionComponent<IconProps>;
}

export interface Navigation {
  isGroupNavigation: boolean;
  groupName?: string;
  nav: NavigationLink[];
}

export type Page = {
  text: string;
  name: PageName;
  to: string;
  icon: SvgIconComponent | React.FunctionComponent<IconProps>;
};

export type OverviewPage = {
  text: string;
  name: OverviewPages;
  to: string;
  icon: SvgIconComponent | React.FunctionComponent<IconProps>;
};

export type OverviewPages =
  | "events"
  | "customCharts"
  | "reports"
  | "a4GateMonitoring"
  | "overall-line-efficiency"
  | "intellecta";

export type PageName =
  | "landingPage"
  | "machineDetail"
  | "efficiencyNavigator"
  | "batchNavigator"
  | "acceptanceTest"
  | "configurable-oee"
  | "download"
  | "motors"
  | "analytics-filler"
  | "analytics-isolator"
  | "analytics-lyo"
  | "smartTrack"
  | "predictiveAnalytics"
  | "machineInfo"
  | "jams"
  | "physicalMeasures"
  | "accessRequest"
  | "adjustment"
  | "alarms"
  | "rejectCauses"
  | "materials"
  | "efficiencyStates"
  | "machinePerformance"
  | "changeOver"
  | "stopJustification"
  | "machineSettings"
  | "stateLog"
  | "eventsLog"
  | "historicMachineDetail";

export const includes = (pages: PageName[], page: PageName): boolean => {
  return pages.includes(page);
};

export const baseDefaultPages: Page[] = [
  {
    text: "Home",
    name: "landingPage",
    to: "/home",
    icon: HomeOutlined,
  },
  {
    text: "Machine Detail",
    name: "machineDetail",
    to: "/machine-detail",
    icon: PieChartOutlineOutlined,
  },
];

export const basePages: Page[] = [
  {
    text: "Machine Performance",
    name: "machinePerformance",
    to: "/machine-performance",
    icon: SpeedOutlined,
  },
  {
    text: "Stop Justification",
    name: "stopJustification",
    to: "/stop-justification",
    icon: PhonelinkEraseOutlined,
  },
  {
    text: "Machine Settings",
    name: "machineSettings",
    to: "/machine-settings",
    icon: SettingsOutlinedIcon,
  },
  {
    text: "Download",
    name: "download",
    to: "/download",
    icon: FileDownloadOutlined,
  },
  {
    text: "Batch Navigator",
    name: "batchNavigator",
    to: "/batch-navigator",
    icon: PlaceOutlined,
  },
  {
    text: "Predictive Analytics",
    name: "predictiveAnalytics",
    to: "/predictive-analytics",
    icon: InsightsOutlined,
  },
  {
    text: "Efficiency Navigator",
    name: "efficiencyNavigator",
    to: "/efficiency-navigator",
    icon: LeaderboardOutlined,
  },
  {
    text: "Acceptance Test",
    name: "acceptanceTest",
    to: "/acceptance-test",
    icon: InventoryOutlined,
  },
  {
    text: "Configurable Oee",
    name: "configurable-oee",
    to: "/configurable-oee",
    icon: TuneOutlined,
  },
  {
    text: "Motors",
    name: "motors",
    to: "/motors",
    icon: BuildOutlined,
  },
  {
    text: "Efficiency States",
    name: "efficiencyStates",
    to: "/efficiency-states",
    icon: AlignHorizontalLeftOutlined,
  },
  {
    text: "Analytics",
    name: "analytics-filler",
    to: "/analytics/filler",
    icon: PsychologyAltOutlined,
  },
  {
    text: "Analytics",
    name: "analytics-isolator",
    to: "/analytics/isolator",
    icon: PsychologyAltOutlined,
  },
  {
    text: "Analytics",
    name: "analytics-lyo",
    to: "/analytics/lyophilizer/summary/production-overview",
    icon: PsychologyAltOutlined,
  },
  {
    text: "Smart Track",
    name: "smartTrack",
    to: "/smart-track",
    icon: RuleOutlined,
  },
  {
    text: "Machine Info",
    name: "machineInfo",
    to: "/machine-info",
    icon: FeedOutlined,
  },
  {
    text: "Events Log",
    name: "eventsLog",
    to: "/events-log",
    icon: Event,
  },
];

export const monitoringPages: Page[] = [
  {
    text: "Jams",
    name: "jams",
    to: "/jams",
    icon: ElectricMeterOutlined,
  },
  {
    text: "Physical Measures",
    name: "physicalMeasures",
    to: "/physical-measures",
    icon: ThermostatOutlined,
  },
  {
    text: "Access Request",
    name: "accessRequest",
    to: "/access-request",
    icon: MeetingRoomOutlined,
  },
  {
    text: "Adjustment",
    name: "adjustment",
    to: "/adjustment",
    icon: TrackChangesOutlined,
  },
  {
    text: "Alarms",
    name: "alarms",
    to: "/alarms",
    icon: WarningAmberRounded,
  },
  {
    text: "Reject Causes",
    name: "rejectCauses",
    to: "/reject-causes",
    icon: BallotOutlined,
  },
  {
    text: "Materials",
    name: "materials",
    to: "/materials",
    icon: Inventory2Outlined,
  },
  {
    text: "Change Over",
    name: "changeOver",
    to: "/change-over",
    icon: ChangeCircleOutlined,
  },
];

export const overviewPages: OverviewPage[] = [
  {
    icon: AccountTreeOutlined,
    name: "overall-line-efficiency",
    text: "Overall Line Efficiency",
    to: "/overview/overall-line-efficiency",
  },
  {
    icon: AodOutlined,
    name: "a4GateMonitoring",
    text: "A4Gate Monitoring",
    to: "/overview/a4gate-monitoring",
  },
  {
    icon: DashboardCustomize,
    name: "customCharts",
    text: "Custom Charts",
    to: "/overview/custom-charts",
  },
  {
    icon: EditCalendarOutlined,
    name: "events",
    text: "Events",
    to: "/overview/events",
  },
  {
    icon: BrowserUpdatedOutlined,
    name: "reports",
    text: "Reports",
    to: "/overview/reports",
  },
  {
    icon: ForumOutlined,
    name: "intellecta",
    text: "Intellecta",
    to: "/overview/intellecta",
  },
];
