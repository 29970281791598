import { useState } from "react";
import { ExpandLess, ExpandMore, Circle } from "@mui/icons-material";
import {
  Box,
  ListItemButton,
  ListItemText,
  Collapse,
  List,
  Checkbox,
  ListItemIcon,
  Tooltip,
} from "@mui/material";
import {
  useMotorsContext,
  useMotorsDispatchContext,
} from "../context/MotorsContextProvider";
import { Frame } from "../types";
import { mapStatus } from "../utils/mapStatus";

interface FrameSelectGroupProps {
  parentName: string;
  children: Frame[];
}

export const FrameSelectGroup = ({
  parentName,
  children,
}: FrameSelectGroupProps) => {
  const [open, setOpen] = useState(false);
  const { frames } = useMotorsContext();
  const dispatch = useMotorsDispatchContext();

  const handleClick = () => {
    setOpen(!open);
  };

  const handleFrameClick = (frame: Frame) => {
    if (frames.find((obj) => obj.id === frame.id)) {
      dispatch({ type: "REMOVE_FRAME", item: frame });
    } else {
      dispatch({ type: "ADD_FRAME", item: frame });
    }
  };

  return (
    <Box>
      <ListItemButton key={parentName} onClick={handleClick}>
        <ListItemText primary={parentName} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {children.map((child) => (
            <ListItemButton
              key={child.id}
              sx={{ pl: 2 }}
              onClick={() => handleFrameClick(child)}
            >
              <Checkbox
                checked={
                  frames.find((obj) => obj.id === child.id) ? true : false
                }
                color="primary"
              />
              <ListItemText
                primary={`${child.timelapse} - ${new Date(
                  child.dateStart,
                ).toLocaleString("en-US")}`}
              />
              <ListItemIcon>
                <Tooltip title={child.status}>
                  <Circle
                    sx={{
                      color: mapStatus(child.status),
                      height: ".8rem",
                      pl: ".5rem",
                    }}
                  />
                </Tooltip>
              </ListItemIcon>
            </ListItemButton>
          ))}
        </List>
      </Collapse>
    </Box>
  );
};
