import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { useMotorsContext } from "../context/MotorsContextProvider";
import { Frame } from "../types";
import { z } from "zod";
import { ONE_MINUTE } from "@/utils/durationsInMilliseconds";

type DataSummarySpecificResponse = ThingworxError | DataSummarySpecificResult;

const SummaryData = z.record(
  z.record(
    z.object({
      description: z.string(),
      status: z.record(z.number()),
      frame_status: z.record(z.number()),
      pre_frame_status: z.record(z.number()),
      totalFrames: z.number(),
    }),
  ),
);

export type SummaryData = z.infer<typeof SummaryData>;

const DataSummarySpecificResult = z.object({
  response: z.literal(true),
  cards: SummaryData,
});

export type DataSummarySpecificResult = z.infer<
  typeof DataSummarySpecificResult
>;

interface Payload {
  machineName: string;
  framesList: Frame[];
}

export const useGetDataSummarySpecific = () => {
  const { machine } = useMachineContext();
  const { frames } = useMotorsContext();
  const { appKey } = useFirebaseContext();

  return useQuery<SummaryData>({
    queryKey: [
      "summary-data-specific",
      machine?.machine,
      JSON.stringify(frames),
    ],
    queryFn: async () => {
      const response = await FetchClient<Payload, DataSummarySpecificResponse>({
        appKey,
        payload: {
          machineName: machine?.machine || "",
          framesList: frames,
        },
        url: "dig.c.motorSummary_thing/Services/getDataSummarySpecific",
      });

      if (!response.response) throw new Error(response.errorString);

      const validatedResponse = DataSummarySpecificResult.parse(response);
      return validatedResponse.cards;
    },
    refetchInterval: ONE_MINUTE,
  });
};
