import {
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  TextField,
  Button,
} from "@mui/material";

import { useForm, Controller } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import { z } from "zod";
import { Line } from "../../api/useGetUserProductionLines";
import { useSetAutomaticConfig } from "../../Pages/LineOverview/api/useSetAutomaticConfig";
import { useTranslate } from "@/i18n/config";

const AutomaticConfigSchema = z.object({
  line_output: z.string(),
  reference_machine: z.string(),
  target_oee: z.number().min(0).max(100),
  reference_rate: z.number().min(0),
  output_rate: z.number().min(0),
});

type AutomaticConfigSchemaType = z.infer<typeof AutomaticConfigSchema>;

export const AutomaticConfigForm = ({
  line,
  onSubmit,
}: {
  line: Line;
  onSubmit: () => void;
}) => {
  const translate = useTranslate();

  const { mutate: setAutomaticConfig } = useSetAutomaticConfig();

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm<AutomaticConfigSchemaType>({
    defaultValues: {
      line_output: "",
      reference_machine: "",
    },
  });

  const referenceMachine = watch("reference_machine");
  const outputMachine = watch("line_output");

  return (
    <form
      onSubmit={handleSubmit((data) => {
        setAutomaticConfig(
          {
            line_id: line.line_id,
            reference_machine_id: data.reference_machine,
            target_oee: data.target_oee,
            line_output_id: data.line_output,
            reference_rate: data.reference_rate,
            output_rate: data.output_rate,
          },
          {
            onSuccess: () => onSubmit(),
          },
        );
      })}
    >
      <Stack gap={4} p={2}>
        <FormControl fullWidth>
          <InputLabel size="small" id="demo-simple-select-label2">
            {translate("overall_line_efficiency.machine_line_output")}
          </InputLabel>

          <Controller
            name="line_output"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Required",
              },
            }}
            defaultValue={outputMachine}
            render={({ field: { onChange, ...rest } }) => (
              <Select
                {...rest}
                onChange={(e) => onChange(e.target.value)}
                size="small"
                label="Machine line output"
                error={!!errors.line_output}
              >
                {line.line_machines
                  .filter((machine) => machine.machine_type === "cyclic")
                  .map((machine) => (
                    <MenuItem key={machine.name} value={machine.name}>
                      {machine.name}
                    </MenuItem>
                  ))}
              </Select>
            )}
          />
          {errors.line_output ? (
            <FormHelperText error>{errors.line_output.message}</FormHelperText>
          ) : null}
          <FormHelperText>
            {translate(
              "overall_line_efficiency.machine_line_output_description",
            )}
          </FormHelperText>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel size="small" id="demo-simple-select-label">
            {translate("overall_line_efficiency.main_machine_reference")}
          </InputLabel>
          <Controller
            name="reference_machine"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Required",
              },
            }}
            defaultValue={referenceMachine}
            render={({ field: { onChange, ...rest } }) => (
              <Select
                {...rest}
                size="small"
                onChange={(e) => {
                  onChange(e.target.value);
                }}
                label="Main Machine Reference"
                error={!!errors.reference_machine}
              >
                {line.line_machines
                  .filter((machine) => machine.machine_type === "cyclic")
                  .map((machine) => (
                    <MenuItem key={machine.name} value={machine.name}>
                      {machine.name}
                    </MenuItem>
                  ))}
              </Select>
            )}
          />
          {errors.reference_machine ? (
            <FormHelperText error>
              {errors.reference_machine.message}
            </FormHelperText>
          ) : null}

          <FormHelperText error={!!errors.reference_machine}>
            {translate(
              "overall_line_efficiency.main_machine_reference_description",
            )}
          </FormHelperText>
        </FormControl>

        <FormControl fullWidth>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Controller
              name="reference_rate"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Required",
                },
                min: {
                  value: 0,
                  message: "Can't be less than 0",
                },
              }}
              render={({ field: { onChange, value } }) => {
                return (
                  <NumericFormat
                    customInput={TextField}
                    size="small"
                    fullWidth
                    value={value}
                    name="reference_rate"
                    label={`Reference rate ${referenceMachine || ""}`}
                    helperText={
                      errors.reference_rate && errors.reference_rate.message
                    }
                    thousandSeparator
                    error={!!errors.reference_rate}
                    onValueChange={(v) => onChange(Number(v.value))}
                  />
                );
              }}
            />
            <Controller
              name="output_rate"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Required",
                },
                min: {
                  value: 0,
                  message: "Can't be less than 0",
                },
              }}
              render={({ field: { onChange, value } }) => {
                return (
                  <NumericFormat
                    customInput={TextField}
                    size="small"
                    fullWidth
                    value={value}
                    name="output_rate"
                    label={`Output rate ${outputMachine || ""}`}
                    helperText={
                      errors.output_rate && errors.output_rate.message
                    }
                    thousandSeparator
                    error={!!errors.output_rate}
                    onValueChange={(v) => onChange(Number(v.value))}
                  />
                );
              }}
            />
          </Stack>

          <FormHelperText>
            {translate("overall_line_efficiency.output_rate_description")}
          </FormHelperText>
        </FormControl>

        <FormControl fullWidth>
          <Controller
            name="target_oee"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Required",
              },
              max: {
                value: 100,
                message: "Can't be more than 100",
              },
              min: {
                value: 0,
                message: "Can't be less than 0",
              },
            }}
            render={({ field: { onChange, value } }) => {
              return (
                <NumericFormat
                  customInput={TextField}
                  size="small"
                  value={value}
                  name="target_oee"
                  label="Target Oee"
                  helperText={errors.target_oee && errors.target_oee.message}
                  allowNegative={false}
                  max={100}
                  allowedDecimalSeparators={[".", ","]}
                  suffix="%"
                  error={!!errors.target_oee}
                  onValueChange={(v) => onChange(Number(v.value))}
                />
              );
            }}
          />
          <FormHelperText>
            {translate("overall_line_efficiency.target_oee_description")}
          </FormHelperText>
        </FormControl>

        <Stack direction="row" gap={4} justifyContent="end">
          <Button
            sx={{ alignSelf: "end" }}
            variant="contained"
            color="error"
            onClick={onSubmit}
          >
            {translate("actions.cancel")}
          </Button>
          <Button
            sx={{ alignSelf: "end" }}
            variant="contained"
            color="success"
            type="submit"
          >
            {translate("actions.save")}
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};
