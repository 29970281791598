import { Piechart } from "@/components/highcharts/piechart/Piechart";
import { SelectTimeSelection } from "@/components/SelectTimeSelection";
import {
  useGetSentinelLiveData,
  PieData,
  StateCategory,
} from "@/features/MachineDetail/api/CyclicLive/useGetSentinelLiveData";
import { SmallCircleLayout } from "@/features/MachineDetail/components/CircleLayout";
import { GaugeLayout } from "@/features/MachineDetail/components/GaugeLayout";
import { ProductCounter } from "@/features/MachineDetail/components/ProductCounter";
import { MachineViewNavBar } from "@/pages/MachineView/layout/MachineViewNavBar/MachineViewNavBar";
import { Box, Grid, Popover, Stack, Typography } from "@mui/material";
import { SpeedInfo } from "@/features/MachineDetail/components/SpeedInfo";
import { colors } from "@/styles/colors";
import { CurrentMachineState } from "@/features/MachineDetail/components/CurrentMachineState";
import { Recipe } from "@/features/MachineDetail/components/Recipe";
import { useGetRecipe } from "@/features/MachineDetail/api/useGetRecipe";
import { NavButton } from "@/components/NavButton";
import { useTimeSelection } from "@/store/useTimeSelection";
import { DateTime } from "luxon";
import { useNavigate } from "react-router-dom";
import { LiveLoadingView } from "./LiveLoadingView";
import { useCheckMachinePage } from "@/hooks/check-machine-page";
import { useTranslate } from "@/i18n/config";
import { useState } from "react";

export const SentinelLiveView = () => {
  const { data, isLoading, error } = useGetSentinelLiveData();
  const { hasAccess } = useCheckMachinePage("historicMachineDetail");
  const translate = useTranslate();

  if (isLoading) {
    return <LiveLoadingView />;
  }

  if (error) {
    return <div>{translate("user_feedback.an_error_occurred")}</div>;
  }

  if (!data) {
    return <div>{translate("user_feedback.no_data")}</div>;
  }

  return (
    <>
      <MachineViewNavBar>
        <Stack
          direction={{ sm: "row", xs: "column" }}
          gap="1rem"
          alignItems="center"
        >
          <SelectTimeSelection exclude={["Custom"]} />
          {hasAccess ? (
            <NavButton
              to="/machine-detail/sentinel/historic"
              text={translate("view_historic")}
            />
          ) : null}
        </Stack>
      </MachineViewNavBar>

      {data ? (
        <Stack gap={2}>
          <TopRow {...data} />
          <KpiInfo {...data} />
          <SpeedInfo />
        </Stack>
      ) : null}
    </>
  );
};

function Alarm({
  alarmDescription,
  firstAlarm,
}: {
  alarmDescription: string;
  firstAlarm: number;
}) {
  const { hasAccess } = useCheckMachinePage("alarms");
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const navigate = useNavigate();
  const translate = useTranslate();
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <SmallCircleLayout
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        onClick={() => {
          if (hasAccess) {
            navigate("/alarms");
          }
        }}
        sx={{
          fontSize: {
            xs: ".75rem",
            sm: "1rem",
          },
          textAlign: {
            xs: "center",
          },
          cursor: "pointer",
        }}
      >
        <Typography
          sx={{
            color: "red",
          }}
        >
          {translate("alarms")}
        </Typography>
        <Box>{firstAlarm === 0 ? translate("no_alarm") : firstAlarm}</Box>
      </SmallCircleLayout>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 1 }}>{alarmDescription}</Typography>
      </Popover>
    </>
  );
}

function TopRow({
  wasteCounterMachine,
  alarmDescription,
  firstAlarm,
  stateColor,
  stateString,
  recipe,
  recipeChanged,
  recipeClient,
  pieData,
  category,
  totalProduct,
  goodCounter,
  wasteCounterInteraction,
  wasteCounterLine, // timelapseStart,
} // timelapseEnd,
: {
  wasteCounterMachine: number;
  alarmDescription: string;
  stateColor: string;
  stateString: string;
  recipe: string;
  recipeChanged: boolean;
  recipeClient: string;
  pieData: PieData[];
  firstAlarm: number;
  category: StateCategory;
  totalProduct: number;
  goodCounter: number;
  wasteCounterInteraction: number;
  wasteCounterLine: number;
  // timelapseStart: number;
  // timelapseEnd: number;
}) {
  const { data: recipeContent } = useGetRecipe();
  const { timeSelection } = useTimeSelection();
  const { hasAccess } = useCheckMachinePage("stateLog");
  const translate = useTranslate();
  return (
    <Grid container columns={12} alignItems="center" spacing={2}>
      <Grid item xs={6} md={2}>
        <ProductCounter
          page={"machineDetail"}
          counter={totalProduct}
          text={translate("machine.products")}
          goodCounter={goodCounter}
          machineWaste={wasteCounterMachine}
          lineWaste={wasteCounterLine}
          interactionWaste={wasteCounterInteraction}
        />
      </Grid>
      <Grid justifyContent="center" item xs={6} md={2}>
        <Alarm alarmDescription={alarmDescription} firstAlarm={firstAlarm} />
      </Grid>
      <Grid item xs={12} md={4}>
        <Stack gap={2}>
          <CurrentMachineState
            category={category}
            color={stateColor}
            state={stateString}
            linkTo={hasAccess ? "/state-log" : undefined}
            dateTimeHandler={() => {
              useTimeSelection.setState((prevState) => ({
                ...prevState,
                dates: {
                  // dateStart: DateTime.fromMillis(timelapseStart),
                  // dateEnd: DateTime.fromMillis(timelapseEnd),
                  dateStart:
                    timeSelection === "Day"
                      ? DateTime.now().minus({ days: 1 })
                      : timeSelection === "Week"
                      ? DateTime.now().minus({ weeks: 1 })
                      : timeSelection === "Month"
                      ? DateTime.now().minus({ months: 1 })
                      : DateTime.now().minus({ hours: 8 }),
                  dateEnd: DateTime.now(),
                },
              }));
            }}
          />
          <Recipe
            recipe={recipe}
            recipeChanged={recipeChanged}
            recipeClient={recipeClient}
            recipeContent={recipeContent}
          />
        </Stack>
      </Grid>
      <Grid alignItems="center" justifyContent="center" item xs={12} md={4}>
        <Box
          sx={{
            paddingX: "5%",
            height: {
              sx: "200px",
              md: "auto",
            },
          }}
        >
          <Piechart data={pieData} label="" title="" version="machineDetail" />
        </Box>
      </Grid>
    </Grid>
  );
}

function KpiInfo({
  availability,
  performance,
  quality,
  oee,
  elapsedTime,
  inactiveTime,
  uptime,
  totalTime,
}: {
  availability: number;
  performance: number;
  quality: number;
  oee: number;
  elapsedTime: number;
  inactiveTime: number;
  uptime: number;
  totalTime: number;
}) {
  const translate = useTranslate();
  return (
    <Grid id="machine-detail-gauge" container columns={8} alignItems="center">
      <Grid
        item
        sx={{ margin: "auto" }}
        md={1}
        alignItems="center"
        flex={1}
        justifyContent="center"
      >
        <GaugeLayout
          dimension="small"
          type="percentage"
          min={0}
          max={100}
          color={colors.kpi.availability}
          title={translate("kpi.availability")}
          value={availability}
        />
      </Grid>

      <Grid
        item
        sx={{ margin: "auto" }}
        md={1}
        alignItems="center"
        flex={1}
        justifyContent="center"
      >
        <GaugeLayout
          dimension="small"
          type="percentage"
          color={colors.kpi.performance}
          min={0}
          max={100}
          title={translate("kpi.performance")}
          value={performance}
        />
      </Grid>
      <Grid
        item
        sx={{ margin: "auto" }}
        md={1}
        alignItems="center"
        flex={1}
        justifyContent="center"
      >
        <GaugeLayout
          dimension="small"
          min={0}
          color={colors.kpi.quality}
          max={100}
          type="percentage"
          title={translate("kpi.quality")}
          value={quality}
        />
      </Grid>
      <Grid
        item
        sx={{ margin: "auto" }}
        md={2}
        alignItems="center"
        flex={1}
        justifyContent="center"
      >
        <GaugeLayout
          dimension="large"
          color={colors.kpi.oee}
          min={0}
          max={100}
          type="percentage"
          title={translate("kpi.oee")}
          value={oee}
        />
      </Grid>
      <Grid
        item
        sx={{ margin: "auto" }}
        md={1}
        alignItems="center"
        flex={1}
        justifyContent="center"
      >
        <GaugeLayout
          dimension="small"
          color={colors.kpi.elapsedTime}
          min={0}
          max={totalTime * 1000}
          type="duration"
          title={translate("machine.elapsed_time")}
          value={elapsedTime * 1000}
        />
      </Grid>
      <Grid
        item
        sx={{ margin: "auto" }}
        md={1}
        alignItems="center"
        flex={1}
        justifyContent="center"
      >
        <GaugeLayout
          dimension="small"
          type="duration"
          min={0}
          color={colors.kpi.inactiveTime}
          max={totalTime * 1000}
          title={translate("machine.inactive_time")}
          value={inactiveTime * 1000}
        />
      </Grid>
      <Grid
        item
        sx={{ margin: "auto" }}
        md={1}
        alignItems="center"
        flex={1}
        justifyContent="center"
      >
        <GaugeLayout
          dimension="small"
          type="duration"
          color={colors.kpi.uptime}
          min={0}
          max={totalTime * 1000}
          title={translate("machine.uptime")}
          value={uptime * 1000}
        />
      </Grid>
    </Grid>
  );
}
