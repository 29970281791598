import i18next from "i18next";
import { initReactI18next, useTranslation } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import * as languages from "./locales";

export type Translations = keyof (typeof languages)["en"];

export type Languages = keyof typeof languages;

const resources = Object.entries(languages).reduce(
  (acc, [language, translation]) => {
    acc[language] = { translation };
    return acc;
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  {} as Record<string, { translation: any }>,
);

// eslint-disable-next-line import/no-named-as-default-member
i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    debug: process.env.NODE_ENV === "development",
    fallbackLng: "en",
  });

export const useTranslate = () => {
  const { t } = useTranslation();

  return function (
    key: Translations,
    options?: Partial<Parameters<typeof t>[1]> & Record<string, unknown>,
  ) {
    return t(key, { ...options });
  };
};

export const useChangeLanguage = () => {
  const { i18n } = useTranslation();

  return function (lang: Languages) {
    i18n.changeLanguage(lang);
  };
};

export const useLanguage = () => {
  const { i18n } = useTranslation();

  return i18n.language as Languages;
};
