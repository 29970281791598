import { useReducer, createContext, useContext } from "react";
import { Frame } from "../types";
import { z } from "zod";

const Item = z.object({
  timelapse: z.string(),
  dateStart: z.number(),
  dateEnd: z.number(),
  id: z.number(),
});

export type Item = z.infer<typeof Item>;

export type Timelapse = "recipes" | "batches";

const Motor = z.object({
  motor_id: z.number(),
});

export type Motor = z.infer<typeof Motor>;

export type MotorsContextType = {
  timelapse: Timelapse;
  recipesFavorites: Item[];
  batchesFavorites: Item[];
  frames: Frame[];
  motors: number[];
};

export const MotorsContext = createContext<MotorsContextType | null>(null);

export const MotorsDispatchContext =
  createContext<React.Dispatch<Action> | null>(null);

export const useMotorsDispatchContext = () => {
  const context = useContext(MotorsDispatchContext);

  if (!context) {
    throw new Error(
      "MotorsDispatchContext must be used within MotorsContextProvider",
    );
  }
  return context;
};

export const useMotorsContext = () => {
  const context = useContext(MotorsContext);

  if (!context) {
    throw new Error("MotorsContext must be used within MotorsContextProvider");
  }
  return context;
};

type Action =
  | {
      type: "ADD_RECIPE";
      item: Item;
    }
  | {
      type: "REMOVE_RECIPE";
      item: Item;
    }
  | {
      type: "CLEAR_RECIPES";
    }
  | {
      type: "ADD_BATCH";
      item: Item;
    }
  | {
      type: "REMOVE_BATCH";
      item: Item;
    }
  | {
      type: "CLEAR_BATCHES";
    }
  | {
      type: "ADD_FRAME";
      item: Frame;
    }
  | {
      type: "REMOVE_FRAME";
      item: Frame;
    }
  | {
      type: "CLEAR_FRAMES";
    }
  | {
      type: "SET_TIMELAPSE";
      timelapse: Timelapse;
    }
  | {
      type: "ADD_MOTOR";
      item: number;
    }
  | {
      type: "REMOVE_MOTOR";
      item: number[];
    }
  | {
      type: "CLEAR_MOTORS";
    };

const reducer: React.Reducer<MotorsContextType, Action> = (
  state,
  action,
): MotorsContextType => {
  switch (action.type) {
    case "ADD_RECIPE": {
      if (
        !state.recipesFavorites.find(
          (obj) =>
            obj.id === action.item.id &&
            obj.dateStart === action.item.dateStart &&
            obj.dateEnd === action.item.dateEnd,
        )
      ) {
        return {
          ...state,
          recipesFavorites: [...state.recipesFavorites, action.item],
        };
      } else {
        return state;
      }
    }

    case "REMOVE_RECIPE": {
      if (
        state.recipesFavorites.find(
          (obj) =>
            obj.id === action.item.id &&
            obj.dateStart === action.item.dateStart &&
            obj.dateEnd === action.item.dateEnd,
        )
      ) {
        return {
          ...state,
          recipesFavorites: state.recipesFavorites.filter(
            (item) =>
              `${item.id}-${item.timelapse}-${item.dateStart}-${item.dateEnd}` !==
              `${action.item.id}-${action.item.timelapse}-${action.item.dateStart}-${action.item.dateEnd}`,
          ),
        };
      } else {
        return state;
      }
    }

    case "CLEAR_RECIPES": {
      return {
        ...state,
        recipesFavorites: [],
        frames: [],
      };
    }
    case "ADD_BATCH": {
      if (
        !state.batchesFavorites.find(
          (obj) =>
            obj.id === action.item.id &&
            obj.dateStart === action.item.dateStart &&
            obj.dateEnd === action.item.dateEnd,
        )
      )
        return {
          ...state,
          batchesFavorites: [...state.batchesFavorites, action.item],
        };
      else return state;
    }

    case "REMOVE_BATCH": {
      const found = state.batchesFavorites.find(
        (obj) =>
          obj.id === action.item.id &&
          obj.dateStart === action.item.dateStart &&
          obj.dateEnd === action.item.dateEnd,
      );

      if (found) {
        return {
          ...state,
          batchesFavorites: state.batchesFavorites.filter(
            (item) =>
              `${item.id}-${item.timelapse}` !==
              `${action.item.id}-${action.item.timelapse}`,
          ),
        };
      }
      return state;
    }
    case "CLEAR_BATCHES": {
      return {
        ...state,
        batchesFavorites: [],
        frames: [],
      };
    }

    case "ADD_FRAME": {
      if (!state.frames.find((obj) => obj.id === action.item.id))
        return {
          ...state,
          frames: [...state.frames, action.item],
        };
      else return state;
    }

    case "REMOVE_FRAME": {
      if (state.frames.find((obj) => obj.id === action.item.id)) {
        return {
          ...state,
          frames: state.frames.filter(
            (item) =>
              `${item.id}-${item.timelapse}` !==
              `${action.item.id}-${action.item.timelapse}`,
          ),
        };
      } else {
        return state;
      }
    }
    case "CLEAR_FRAMES": {
      return {
        ...state,
        frames: [],
      };
    }

    case "SET_TIMELAPSE": {
      return {
        ...state,
        timelapse: action.timelapse,
      };
    }

    case "ADD_MOTOR": {
      if (!state.motors.find((el) => el === action.item))
        return {
          ...state,
          motors: [...state.motors, action.item],
        };
      else return state;
    }

    case "REMOVE_MOTOR": {
      const idToBeRemoved: number | undefined = state.motors.find(
        (num) => !action.item.includes(num),
      );
      if (idToBeRemoved) {
        if (state.motors.find((el) => el === idToBeRemoved)) {
          return {
            ...state,
            motors: state.motors.filter((item) => item !== idToBeRemoved),
          };
        } else {
          return state;
        }
      } else {
        return state;
      }
    }

    case "CLEAR_MOTORS": {
      return {
        ...state,
        motors: [],
      };
    }

    default:
      return state;
  }
};

export const MotorsContextProvider = ({
  children,
}: React.PropsWithChildren) => {
  const [state, dispatch] = useReducer(reducer, {
    timelapse: "recipes",
    recipesFavorites: [],
    batchesFavorites: [],
    frames: [],
    motors: [],
  });

  const motorsContext: MotorsContextType = {
    timelapse: state.timelapse,
    recipesFavorites: state.recipesFavorites,
    batchesFavorites: state.batchesFavorites,
    frames: state.frames,
    motors: state.motors,
  };

  return (
    <MotorsContext.Provider value={motorsContext}>
      <MotorsDispatchContext.Provider value={dispatch}>
        {children}
      </MotorsDispatchContext.Provider>
    </MotorsContext.Provider>
  );
};
