import { createContext, ReactElement, useContext } from "react";
import { useImmerReducer } from "use-immer";
import { Line } from "@/features/OverallLineEfficiency/api/useGetUserProductionLines";
import { LineConfig } from "../../Edit/types";

type State = {
  editMode: boolean;
  selectedLine?: Line;
  view_mode: "automatic" | "manual";
};

export type NestedTree = {
  company_id: number;
  company_name: string;
  factories: {
    factory_id: number;
    factory_name: string;
    lines: Line[];
  }[];
};

type Action =
  | {
      type: "select line";
      line: Line;
    }
  | {
      type: "update line";
      line_id: number;
      config: LineConfig;
    }
  | {
      type: "enable edit mode";
    }
  | {
      type: "disable edit mode";
    }
  | {
      type: "set view mode";
      view_mode: "automatic" | "manual";
    };

const reducer = (draft: State, action: Action) => {
  switch (action.type) {
    case "select line":
      if (draft.selectedLine?.line_id === action.line.line_id) {
        draft.selectedLine = undefined;
        return;
      } else {
        draft.editMode = false;
      }
      draft.selectedLine = action.line;
      break;

    /**
     * used to optimistically update the line configuration when the user edits the line
     */
    case "update line":
      if (draft.selectedLine?.line_id === action.line_id) {
        draft.selectedLine.line_configuration = action.config;
      }

      break;
    case "enable edit mode":
      draft.editMode = true;
      break;

    case "disable edit mode":
      draft.editMode = false;
      break;

    case "set view mode":
      draft.view_mode = action.view_mode;
      draft.editMode = false;
      draft.selectedLine = undefined;
      break;
    default:
      break;
  }
};

const OeeLineContext = createContext<State | null>(null);

const OeeLineDispatchContext = createContext<React.Dispatch<Action> | null>(
  null
);

export const OeeLineOverviewContextProvider = ({
  children,
}: {
  children: ReactElement;
}) => {
  const [state, dispatch] = useImmerReducer<State, Action>(reducer, {
    editMode: false,
    view_mode: "automatic",
  });

  return (
    <OeeLineContext.Provider value={state}>
      <OeeLineDispatchContext.Provider value={dispatch}>
        {children}
      </OeeLineDispatchContext.Provider>
    </OeeLineContext.Provider>
  );
};

export const useOeeLineContextState = () => {
  const context = useContext(OeeLineContext);
  if (!context) {
    throw new Error(
      "useOeeLineContextState must be used within a OeeLineContextProvider"
    );
  }
  return context;
};

export const useOeeLineContextDispatch = () => {
  const context = useContext(OeeLineDispatchContext);
  if (!context) {
    throw new Error(
      "useOeeLineContextDispatch must be used within a OeeLineContextProvider"
    );
  }
  return context;
};
