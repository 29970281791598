import { useFirebaseContext } from "@/context/firebase-context";
import { FetchClient } from "@/services/ApiClient";
import { useTimeSelection, TimeSelection } from "@/store/useTimeSelection";
import { useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { ThingworxError } from "src/types/error";
import { z } from "zod";
import { alternativeViewStore } from "../store/alternative-view-store";

export type StatesLogResponse = ThingworxError | StatesLogResult;

const statesLogProps = z.object({
  state: z.string(),
  color: z.string(),
  start: z.number(),
  end: z.number(),
});

export type StatesLogProps = z.infer<typeof statesLogProps>;

export const statesLogData = z.record(z.array(statesLogProps));

export type StatesLogData = z.infer<typeof statesLogData>;

const StatesLogResult = z.object({
  response: z.literal(true),
  data: statesLogData,
  order: z.array(z.string()),
});

export type StatesLogResult = z.infer<typeof StatesLogResult>;

type Payload =
  | {
      line_id: number;
      config: "manual";
    }
  | {
      line_id: number;
      config: "automatic";
      timeSelection: TimeSelection;
      dateStart?: DateTime;
      dateEnd?: DateTime;
      viewFromFirstProduct: boolean;
    };

export const useGetStatesLog = ({
  line_id,
  view_mode,
  has_view_from_first_product = false,
}: {
  line_id: number;
  view_mode: "automatic" | "manual";
  has_view_from_first_product?: boolean;
}) => {
  const { appKey } = useFirebaseContext();

  const { viewFromFirstProduct } = alternativeViewStore();

  const useView = has_view_from_first_product ? viewFromFirstProduct : false;

  const {
    timeSelection,
    dates: { dateStart, dateEnd },
  } = useTimeSelection();
  const queryKey =
    view_mode === "manual"
      ? [
          "line-machines-state-log-data",
          line_id,
          view_mode,
          useView ? "custom" : "standard",
        ]
      : timeSelection === "Custom"
      ? [
          "line-machines-state-log-data",
          line_id,
          view_mode,
          timeSelection,
          dateEnd,
          dateStart,
        ]
      : ["line-machines-state-log-data", line_id, view_mode, timeSelection];

  const payload: Payload =
    view_mode === "manual"
      ? {
          line_id,
          config: "manual",
        }
      : timeSelection === "Custom"
      ? {
          line_id,
          config: "automatic",
          timeSelection: "Custom",
          dateStart,
          dateEnd,
          viewFromFirstProduct: useView,
        }
      : {
          line_id,
          config: "automatic",
          timeSelection,
          viewFromFirstProduct: useView,
        };

  return useQuery<StatesLogResult>({
    queryKey,
    queryFn: async () => {
      const response = await FetchClient<Payload, StatesLogResponse>({
        appKey,
        payload,
        url: "dig.c.plantOverview_thing/Services/getStatesLog",
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      const validatedResponse = StatesLogResult.parse(response);
      return validatedResponse;
    },
    refetchInterval: 1000 * 20,
  });
};
