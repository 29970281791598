import { useFirebaseContext } from "@/context/firebase-context";
import { FetchClient } from "@/services/ApiClient";
import { useTimeSelection, TimeSelection } from "@/store/useTimeSelection";
import { QueryBuilder } from "@/utils/query-builder";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { z } from "zod";
import { alternativeViewStore } from "../store/alternative-view-store";

type LineConfigurationResponse = ThingworxError | LineConfigurationResult;

export const TimeGap = z.object({
  percentage_gap: z.number(),
  minutes_gap: z.number(),
  warning_level: z.union([
    z.literal(0),
    z.literal(1),
    z.literal(2),
    z.literal(3),
  ]),
});
export type TimeGap = z.infer<typeof TimeGap>;

export const MachineProducts = z.object({
  machine: z.string(),
  products: z.number(),
  wastes: z.number(),
});
export type MachineProducts = z.infer<typeof MachineProducts>;

export const ProductsGap = z.object({
  total: z.number(),
  current_products: z.number(),
  ideal_current_products: z.number(),
});

export type ProductsGap = z.infer<typeof ProductsGap>;

export const RunningConfiguration = z.object({
  id: z.number(),
  configuration: z.object({
    machineOutput: z.string(),
    targetOee: z.number(),
    startTime: z.number().optional(),
    machineReference: z.string().optional(),
    productionTarget: z.number().optional(),
    scheduledArrivalTime: z.number().optional(),
    referenceRate: z.number().optional(),
    outputRate: z.number().optional(),
  }),
  data: z.object({
    line_efficiency: z.number(),
    output_wastes: z.number(),
    output_products: z.number(),
    products_gap: ProductsGap,
    time_gap: TimeGap,
    products: z.array(MachineProducts),
    state: z.enum([
      "Producing",
      "Not Producing",
      "Idle",
      "Scheduled Downtime",
      "Not Configured",
    ]),
  }),
  is_running: z.literal(true),
  type: z.union([
    z.literal("automatic"),
    z.literal("time_based"),
    z.literal("machine_based"),
  ]),
});
export type RunningConfiguration = z.infer<typeof RunningConfiguration>;

const NotRunningConfiguration = z.object({
  id: z.number(),
  configuration: z.object({
    machineOutput: z.string(),
    targetOee: z.number(),
    startTime: z.number().optional(),
    machineReference: z.string().optional(),
    productionTarget: z.number().optional(),
    scheduledArrivalTime: z.number().optional(),
    referenceRate: z.number().optional(),
    outputRate: z.number().optional(),
  }),
  is_running: z.literal(false),
  type: z.union([
    z.literal("automatic"),
    z.literal("time_based"),
    z.literal("machine_based"),
  ]),
});

export const Configuration = z.discriminatedUnion("is_running", [
  RunningConfiguration,
  NotRunningConfiguration,
]);

export type Configuration = z.infer<typeof Configuration>;

const LineConfigurationResult = z.discriminatedUnion("configured", [
  z.object({
    configured: z.literal(true),
    response: z.literal(true),
    config_values: Configuration,
  }),
  z.object({
    configured: z.literal(false),
    response: z.literal(true),
  }),
]);

type LineConfigurationResult = z.infer<typeof LineConfigurationResult>;

type Payload =
  | {
      config: "manual";
      line_id: number;
    }
  | {
      config: "automatic";
      viewFromFirstProduct: boolean;
      line_id: number;
      timeSelection: TimeSelection;
    };

export const useGetLineConfiguration = ({
  line_id,
  config,
  has_view_from_first_product = false,
}: {
  line_id: number;
  config: "automatic" | "manual";
  has_view_from_first_product?: boolean;
}) => {
  const { appKey } = useFirebaseContext();
  const { viewFromFirstProduct } = alternativeViewStore();

  const { timeSelection } = useTimeSelection();

  const useView = has_view_from_first_product ? viewFromFirstProduct : false;

  const query = QueryBuilder.buildWithCondition({
    baseQuery: [
      "line-configuration",
      line_id,
      config,
      useView ? "custom" : "standard",
    ],
    condition: config === "automatic",
    query: [timeSelection],
  });

  return useQuery<LineConfigurationResult>({
    queryKey: query,
    queryFn: async () => {
      const response = await FetchClient<Payload, LineConfigurationResponse>({
        appKey,
        url: "dig.c.plantOverview_thing/Services/getLineConfiguration",
        payload:
          config === "automatic"
            ? {
                config: "automatic",
                line_id,
                timeSelection,
                viewFromFirstProduct: useView,
              }
            : {
                config: "manual",
                line_id,
              },
      });

      if (!response.response) throw new Error(response.errorString);

      return response;
    },
    refetchInterval: 1000 * 20,
  });
};
