import { useDisclosure } from "@/hooks/useDisclosure";
import { Configuration } from "../api/useGetLineConfiguration";
import { StartConfiguration } from "@/features/OverallLineEfficiency/components/StartConfiguration";
import { StopConfiguration } from "@/features/OverallLineEfficiency/components/StopConfiguration";
import { Box, Stack, Typography, Divider, Button } from "@mui/material";
import { Modal } from "@/components/Modal";
import { TimeHelpers } from "@/utils/TimeHelpers";
import { NumericFormat } from "react-number-format";
import { Card } from "@/components/Layout/Card";
import { HistoricConfiguration } from "@/features/OverallLineEfficiency/Pages/HistoricLineDetail/api/useGetHistoricLineData";
import { useState } from "react";

type ConfigurationKeys = keyof Configuration["configuration"];
const MappedConfigurationKeys: Record<ConfigurationKeys, string> = {
  machineOutput: "Machine Output",
  machineReference: "Machine Reference",
  productionTarget: "Production Target",
  scheduledArrivalTime: "Scheduled Arrival Time",
  startTime: "Start Time",
  targetOee: "Target OEE",
  outputRate: "Output Rate",
  referenceRate: "Reference Rate",
};

const parseConfigurationValue = (
  key: ConfigurationKeys,
  value: number | string,
) => {
  switch (key) {
    case "machineOutput":
    case "machineReference":
      return value;
    case "targetOee":
      return `${value}%`;

    case "scheduledArrivalTime":
    case "startTime":
      return TimeHelpers.parseTimestampToString({
        timestamp: typeof value === "string" ? parseInt(value, 10) : value,
      });

    case "productionTarget":
      return (
        <NumericFormat
          value={value}
          displayType="text"
          thousandSeparator="."
          decimalSeparator=","
        />
      );
    default:
      return String(value);
  }
};

export const LineConfig = ({ config }: { config: Configuration }) => {
  const showControlButtons = config.type !== "automatic";
  const { close, isOpen } = useDisclosure();
  return (
    <>
      <Card
        sx={{
          height: "100%",
        }}
      >
        <Stack gap={2}>
          <Typography variant="h6">Configuration</Typography>
          <Stack
            spacing={1.5}
            divider={<Divider />}
            maxHeight={400}
            overflow="auto"
            padding={2}
          >
            {Object.entries(config.configuration).map(([key, value]) => (
              <Stack key={key} direction="row" justifyContent="space-between">
                <Box>{MappedConfigurationKeys[key as ConfigurationKeys]}</Box>
                <Box>
                  {parseConfigurationValue(
                    key as ConfigurationKeys,
                    value
                  )}
                </Box>
              </Stack>
            ))}
          </Stack>
          {showControlButtons ? (
            <Stack>
              {config.is_running ? (
                <StopConfiguration config_id={config.id} />
              ) : (
                <StartConfiguration config_id={config.id} />
              )}
            </Stack>
          ) : null}
        </Stack>
      </Card>
      <Modal
        open={isOpen}
        onClose={close}
        titleContent="Are you sure you want"
        bodyContent={<></>}
      />
    </>
  );
};

export const HistoricLineConfigViewer = ({
  config,
}: {
  config: HistoricConfiguration[];
}) => {
  const [page, setPage] = useState(0);

  const configPages = config.length;

  const showingConfig = config[page];

  return (
    <Card
      sx={{
        height: "100%",
      }}
    >
      <Stack gap={2}>
        <Typography variant="h6">Configuration</Typography>
        <Stack
          spacing={1.5}
          divider={<Divider />}
          maxHeight={400}
          overflow="auto"
          padding={2}
        >
          {showingConfig
            ? Object.entries(showingConfig).map(([key, value]) => (
                <Stack key={key} direction="row" justifyContent="space-between">
                  <Box>{MappedConfigurationKeys[key as ConfigurationKeys]}</Box>
                  <Box>
                    {parseConfigurationValue(key as ConfigurationKeys, value)}
                  </Box>
                </Stack>
              ))
            : null}
        </Stack>

        {configPages > 1 ? (
          <Stack direction={"row"} justifyContent="space-between">
            <Button
              variant="outlined"
              onClick={() => {
                if (page === 0) return;
                setPage((page) => page - 1);
              }}
              disabled={page === 0}
            >
              prev
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                if (page === configPages - 1) return;
                setPage((page) => page + 1);
              }}
              disabled={page === configPages - 1}
            >
              next
            </Button>
          </Stack>
        ) : null}
      </Stack>
    </Card>
  );
};
