import { MachineViewNavBar } from "@/pages/MachineView/layout/MachineViewNavBar/MachineViewNavBar";
import { Tabs, Tab } from "@mui/material";
import { useState } from "react";
import { Summary } from "./pages/Summary";
import { Monitoring } from "./pages/Monitoring";
import { Diagnostic } from "./pages/Diagnostic";
import { RangePicker } from "@/components/Calendars/RangePicker";
import { ListWithFavorites } from "./components/ListWithFavorites";
import { MotorsContextProvider } from "./context/MotorsContextProvider";
import { FramesSelect } from "./components/FramesSelect";
import { LiveButton } from "./components/LiveButton";
import { useTranslate } from "@/i18n/config";

const SECTIONS = {
  SUMMARY: 0,
  MONITORING: 1,
  DIAGNOSTIC: 2,
};

export const Motors = () => {
  const [section, setSection] = useState(SECTIONS.SUMMARY);
  const translate = useTranslate();

  return (
    <MotorsContextProvider>
      <MachineViewNavBar>
        <RangePicker onPickDate={() => {}} />
        <ListWithFavorites />
        <FramesSelect />
        <LiveButton />
      </MachineViewNavBar>
      <Tabs
        value={section}
        aria-label="motors tabs"
        onChange={(_: React.SyntheticEvent, newValue: number) => {
          setSection(newValue);
        }}
      >
        <Tab value={SECTIONS.SUMMARY} label={translate("summary")} />
        <Tab value={SECTIONS.MONITORING} label={translate("monitoring")} />
        <Tab value={SECTIONS.DIAGNOSTIC} label={translate("diagnostic")} />
      </Tabs>
      {section === SECTIONS.SUMMARY ? (
        <Summary />
      ) : section === SECTIONS.MONITORING ? (
        <Monitoring />
      ) : section === SECTIONS.DIAGNOSTIC ? (
        <Diagnostic />
      ) : null}
    </MotorsContextProvider>
  );
};
