import {
  Stack,
  Button,
  Menu,
  FormControl,
  FormLabel,
  Checkbox,
  ListItem,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useEffect, useState } from "react";
import { useFilteredStates } from "../store/states-list-store";
import { useTranslate } from "@/i18n/config";

export const StateListDropDown = ({
  statesList,
  statesOccurred,
}: {
  statesList: string[];
  statesOccurred: string[];
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { setFilter, filteredStates } = useFilteredStates();
  const translate = useTranslate();

  useEffect(() => {
    setFilter(statesOccurred);
  }, [statesOccurred]);
  // open and close pop
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = ({
    stateName,
  }: {
    stateName: string;
    checkbox: number;
  }) => {
    const currentIndex = filteredStates.findIndex(
      (rollingState) => rollingState === stateName,
    );
    if (currentIndex === -1) {
      const addState: string[] = [...filteredStates, stateName];
      const newFiteredStates: string[] = statesList.filter((rollingState) =>
        addState.includes(rollingState),
      );
      setFilter(newFiteredStates);
    } else {
      setFilter(filteredStates.filter((s) => s !== stateName));
    }
  };

  const handleClear = () => {
    setFilter([]);
  };

  // ALL STATES
  const selectAll = () => {
    setFilter(statesList);
  };

  return (
    <Stack gap={4}>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        variant="outlined"
      >
        {translate("state_log.selected_states")}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{ height: "400px" }}
      >
        <FormControl sx={{ m: 3 }} component="fieldset" variant="filled">
          <FormLabel component="legend">
            {translate("state_log.select_states")}
          </FormLabel>
          <Grid2 container justifyContent="space-between">
            <Button onClick={() => selectAll()}>
              {translate("actions.select_all")}
            </Button>
            <Button onClick={() => handleClear()}>
              {translate("actions.deselect_all")}
            </Button>
          </Grid2>
          <List>
            {statesList.length > 0 &&
              statesList.map((stateName, index) => (
                <ListItem key={index} disablePadding>
                  <ListItemButton
                    role={undefined}
                    onClick={() => handleChange({ stateName, checkbox: index })}
                    dense
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        tabIndex={-1}
                        inputProps={{
                          "aria-labelledby": stateName,
                        }}
                        disableRipple
                        checked={
                          filteredStates.findIndex(
                            (_state) => _state === stateName,
                          ) !== -1
                        }
                        name={index.toString()}
                      />
                    </ListItemIcon>
                    <ListItemText id={index.toString()} primary={stateName} />
                  </ListItemButton>
                </ListItem>
              ))}
          </List>
        </FormControl>
      </Menu>
    </Stack>
  );
};
