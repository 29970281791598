import { useState } from "react";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Box, ListItemButton, ListItemText, Collapse } from "@mui/material";
import { FrameSelectGroup } from "./FrameSelectGroup";
import { Frame } from "../types";

interface FrameSelectGroupLargeProps {
  parentName: string;
  children: Frame[];
}

interface Result {
  [key: string]: Frame[];
}

const parseData = (items: Frame[]): Result => {
  const result: Result = {};

  for (let i = 0; i < items.length; i += 10) {
    const group = items.slice(i, i + 10);
    const startString = new Date(group[0].dateStart).toLocaleString("en-US");
    const endString = new Date(
      group[group.length - 1].dateEnd,
    ).toLocaleTimeString("en-US");
    const key = `${startString} - ${endString}`;
    result[key] = group;
  }

  return result;
};

export const FrameSelectGroupLarge = ({
  parentName,
  children,
}: FrameSelectGroupLargeProps) => {
  const [open, setOpen] = useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  const items = parseData(children);

  return (
    <Box>
      <ListItemButton key={parentName} onClick={handleClick}>
        <ListItemText primary={parentName} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {Object.entries(items).map(([key, items]) => (
          <FrameSelectGroup key={key} parentName={key} children={items} />
        ))}
      </Collapse>
    </Box>
  );
};
