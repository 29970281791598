import { z } from "zod";
import {
  MachineProducts,
  ProductsGap,
  TimeGap,
} from "../../LineDetail/api/useGetLineConfiguration";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { FetchClient } from "@/services/ApiClient";
import { useFirebaseContext } from "@/context/firebase-context";
import { statesLogData } from "@/features/OverallLineEfficiency/Pages/LineDetail/api/useGetStatesLog";
import { AlarmSchema } from "../../LineDetail/api/useGetTopAlarms";
import { LineProducts } from "../../LineDetail/api/useGetLastProductions";
import { alternativeViewStore } from "../../LineDetail/store/alternative-view-store";

type Payload = {
  id: number;
  has_view_from_first_product?: boolean;
  viewFromFirstProduct: boolean;
};

export const HistoricConfiguration = z.object({
  machineOutput: z.string(),
  targetOee: z.number(),
  startTime: z.number().optional(),
  machineReference: z.string().optional(),
  productionTarget: z.number().optional(),
  scheduledArrivalTime: z.number().optional(),
});

export type HistoricConfiguration = z.infer<typeof HistoricConfiguration>;

const HistoricLineBlob = z.object({
  response: z.literal(true),
  data: z.object({
    id: z.number(),
    configuration: z.array(HistoricConfiguration),
    data: z.object({
      line_efficiency: z.number(),
      products_gap: ProductsGap,
      output_wastes: z.number(),
      output_products: z.number(),
      time_gap: TimeGap,
      products: z.array(MachineProducts),
    }),
    type: z.union([
      z.literal("automatic"),
      z.literal("time_based"),
      z.literal("machine_based"),
    ]),
    products_projection: z.object({
      products: z.array(z.array(z.number())),
      products_projection: z.array(z.array(z.number())),
      machine_output: z.string(),
    }),
    line_efficiencies: z.array(
      z.object({
        machine: z.string(),
        values: z.array(z.array(z.number())),
      }),
    ),
    states_log: statesLogData,
    order: z.array(z.string()),
    top_ten_alarms: z.array(AlarmSchema),
    line_production: z.object({
      response: z.literal(true),
      products: z.array(LineProducts),
      averageProducts: z.number(),
    }),
  }),
});

type HistoricLineBlob = z.infer<typeof HistoricLineBlob>;

type HistoricLineResponse = ThingworxError | HistoricLineBlob;

export const useGetHistoricLineData = ({
  id,
  has_view_from_first_product,
}: {
  id: number;
  has_view_from_first_product?: boolean;
}) => {
  const { appKey } = useFirebaseContext();
  const { viewFromFirstProduct } = alternativeViewStore();

  const useView = has_view_from_first_product ? viewFromFirstProduct : false;

  return useQuery<HistoricLineBlob>({
    queryKey: ["historic-line-blob", id, useView ? "custom" : "standard"],
    queryFn: async () => {
      const response = await FetchClient<Payload, HistoricLineResponse>({
        url: "dig.c.plantOverview_thing/Services/getHistoricLineData",
        payload: { id, viewFromFirstProduct },
        appKey,
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      return HistoricLineBlob.parse(response);
    },
  });
};
