import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Box,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { useState, useEffect, useCallback, useMemo } from "react";
import { useOeeLineContextState } from "../../LineOverview/context/oee-line-context";

export const useNodeConfiguration = () => {
  const { selectedLine } = useOeeLineContextState();

  const machines = useMemo(
    () => selectedLine?.line_machines ?? [],
    [selectedLine],
  );

  const [name, setName] = useState("");
  const [isPlaceholder, setIsPlaceholder] = useState(false);

  useEffect(() => {
    setName("");
  }, [isPlaceholder]);

  const renderConfiguration = useCallback(() => {
    return (
      <Box>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={isPlaceholder}
                onChange={(event) => {
                  setIsPlaceholder(event.target.checked);
                }}
                size="small"
              />
            }
            label="Create a placeholder node"
          />
        </FormGroup>
        {isPlaceholder ? (
          <TextField
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setName(event.target.value);
            }}
            placeholder="Insert the name of the placeholder node"
            fullWidth
            error={name === ""}
            helperText={name === "" ? "Please insert a name" : ""}
          />
        ) : (
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="machine-list">Machines</InputLabel>
            <Select
              labelId="machine-list"
              id="machine-list-helper"
              value={name}
              label="Machine"
              onChange={(event) => setName(event.target.value)}
              error={name === ""}
            >
              {machines.map((machine) => (
                <MenuItem key={machine.name} value={machine.name}>
                  {machine.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              Please select the machine you want to add
            </FormHelperText>
          </FormControl>
        )}
      </Box>
    );
  }, [name, isPlaceholder, machines]);

  return { name, isPlaceholder, renderConfiguration };
};
