import { Box, Tooltip, Typography, Zoom } from "@mui/material";
import { ContextInfo } from "../../types";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";

export const ContextInformation = ({ props }: { props: ContextInfo }) => {
  return (
    <>
      {Object.keys(props).length > 0 ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            padding: ".5rem",
            border: "1px solid white",
            borderRadius: ".25rem",
            // boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.25)",
          }}
        >
          {Object.entries(props).map(([key, val], index) => {
            if (typeof val === "string" || typeof val === "number") {
              return (
                <Typography key={index}>
                  {key}: {val}
                </Typography>
              );
            } else {
              const content = (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    maxHeight: 400,
                    overflowY: "auto",
                  }}
                >
                  {Object.entries(val).map(([key, val], index) => (
                    <Typography key={index}>
                      {key}: {val}
                    </Typography>
                  ))}
                </Box>
              );
              return (
                <Tooltip
                  TransitionComponent={Zoom}
                  title={content}
                  key={Math.random()}
                >
                  <Box sx={{ display: "flex", gap: 1, cursor: "help" }}>
                    <Typography>{key}</Typography>
                    <LibraryBooksIcon />
                  </Box>
                </Tooltip>
              );
            }
          })}
        </Box>
      ) : null}
    </>
  );
};
