import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { useMotorsContext } from "../context/MotorsContextProvider";
import { ContextInfo, Frame } from "../types";
import { z } from "zod";

type ResidualsResponse = ThingworxError | ResidualsResult;

const residualsDataSchema = z.array(
  z.object({
    metric: z.number(),
    value: z.number(),
    warningUpper: z.number().optional(),
    warningLower: z.number().optional(),
    criticalUpper: z.number().optional(),
    criticalLower: z.number().optional(),
  }),
);

export type ResidualsDataSchema = z.infer<typeof residualsDataSchema>;

const residualsResSchema = z.object({
  motorName: z.string(),
  frameName: z.string(),
  residualData: residualsDataSchema,
  currentData: z.array(z.array(z.number())),
  contextInfo: ContextInfo,
});

export type ResidualsResSchema = z.infer<typeof residualsResSchema>;

const ResidualsResult = z.object({
  response: z.literal(true),
  data: z.array(residualsResSchema),
});

export type ResidualsResult = z.infer<typeof ResidualsResult>;

interface Payload {
  machineName: string;
  framesList: Frame[];
  motorsList: number[];
}

export const useGetResiduals = () => {
  const { machine } = useMachineContext();
  const { frames, motors } = useMotorsContext();
  const { appKey } = useFirebaseContext();

  return useQuery<ResidualsResSchema[]>({
    queryKey: [
      "residuals-data",
      machine?.machine,
      JSON.stringify(frames),
      JSON.stringify(motors),
    ],
    queryFn: async () => {
      const response = await FetchClient<Payload, ResidualsResponse>({
        appKey,
        payload: {
          machineName: machine?.machine || "",
          framesList: frames,
          motorsList: motors,
        },
        url: "dig.c.motorDecomposition_thing/Services/getResiduals",
      });

      if (!response.response) throw new Error(response.errorString);

      const validatedResponse = ResidualsResult.parse(response);
      return validatedResponse.data;
    },
  });
};
