import { SmallAnalyticContainerLayout } from "@/features/Diagnostic/pages/LYO/components/AnalyticContainer/AnalyticContainerLayout";
import { Card } from "@/components/Layout/Card";
import { Autocomplete, Checkbox, TextField } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {
  useMotorsContext,
  useMotorsDispatchContext,
} from "../context/MotorsContextProvider";
import { Motor, useGetMotorList } from "../api/useGetMotorsList";
import { useTranslate } from "@/i18n/config";

const getNameFromId = (
  motorList: Motor[],
  id: number,
  motorTranslation: string,
): string => {
  const foundMotor = motorList.find((motor) => motor.id === id);
  if (foundMotor) return foundMotor.name;
  return `${motorTranslation} ${id}`;
};

export const MotorSelection = () => {
  const translate = useTranslate();
  const { data, isLoading, error } = useGetMotorList();
  const { motors: contextMotors } = useMotorsContext();
  const dispatch = useMotorsDispatchContext();

  if (isLoading) {
    return <SmallAnalyticContainerLayout sx={{ height: 80 }} />;
  }

  if (error) {
    return <div>{translate("user_feedback.an_error_occurred")}</div>;
  }

  if (!data) {
    return <div>{translate("user_feedback.no_data")}</div>;
  }

  const motorIds = data.map((motor) => motor.id);

  return (
    <Card
      sx={{
        marginTop: ".5rem",
      }}
    >
      <Autocomplete
        multiple
        options={motorIds}
        disableCloseOnSelect
        getOptionLabel={(item) => getNameFromId(data, item, translate("motor"))}
        value={contextMotors}
        onChange={(_, value, reason) => {
          switch (reason) {
            case "clear":
              dispatch({ type: "CLEAR_MOTORS" });
              break;

            case "removeOption":
              if (!Array.isArray(value)) return;
              if (!value) return;

              dispatch({ type: "REMOVE_MOTOR", item: value });
              break;

            case "selectOption":
              if (!Array.isArray(value)) return;
              if (!value) return;

              dispatch({ type: "ADD_MOTOR", item: value[value.length - 1] });
              break;

            default:
              break;
          }
        }}
        renderOption={(props, option) => (
          <li {...props}>
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              sx={{ marginRight: 8 }}
              checked={contextMotors.includes(option)}
            />
            {getNameFromId(data, option, translate("motor"))}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={translate("motor")}
            placeholder={translate("motor")}
          />
        )}
      />
    </Card>
  );
};
