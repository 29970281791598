import { GridColDef } from "@mui/x-data-grid-premium";
import { TimeHelpers } from "./TimeHelpers";

/**
 * The TableParser class is used to convert a generic array of objects into a format that can be used by a table component.
 */
export class TableParser {
  /**
   * Converts data into a format that can be used by a table component.
   *
   * @template T - The type of data being parsed.
   * @param {Object} options - Options for parsing the data.
   * @param {T[]} options.data - The data to parse.
   * @param {Record<keyof T, string>} options.columnsMap - An object that maps the keys of the data to the header names of the columns.
   * @param {(keyof T)[]} options.columnsOrder - An array that specifies the order of the columns.
   *
   * @returns {Object} An object that contains the parsed data in the form of columns and rows.
   * @throws {Error} If data is empty.
   * @throws {Error} If key is not a string.
   *
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static parseData<T extends { [key: string]: any }>({
    data,
    columnsMap,
    columnsOrder,
    omitColumns = [],
    customColumns = [],
    convertDurationToMilliseconds = false,
    dateColumns,
  }: {
    data: T[];
    columnsMap: Record<keyof T, string>;
    columnsOrder: (keyof T)[];
    omitColumns?: (keyof T)[];
    customColumns?: GridColDef<T>[];
    convertDurationToMilliseconds?: boolean;
    dateColumns?: (keyof T)[];
  }) {
    if (!data.length) throw new Error("Data is empty");

    const [firstRow] = data;

    let keys = Object.keys(firstRow) as (keyof T)[];

    keys = keys
      .filter((key) => !omitColumns.includes(key))
      .sort((a, b) => columnsOrder.indexOf(a) - columnsOrder.indexOf(b));

    const columns: GridColDef[] = keys.map((key) => {
      if (typeof key !== "string") throw new Error("Key is not a string");

      const isTimestamp = dateColumns
        ? dateColumns.includes(key)
        : /timestamp/i.test(key.toLowerCase());
      const headerName = columnsMap[key] ? columnsMap[key] : key;

      let columnConfig: GridColDef = {
        field: key,
        headerName,
      };

      if (isTimestamp) {
        columnConfig = {
          ...columnConfig,
          type: "dateTime",
          valueFormatter: ({ value }) => {
            return (
              value &&
              TimeHelpers.parseTimestampToString({
                timestamp: +new Date(value),
              })
            );
          },
          valueGetter: ({ value }) => value && new Date(value),
        };
      }

      return columnConfig;
    });

    columns
      .sort(
        (a, b) =>
          columnsOrder.indexOf(a.field as keyof T) -
          columnsOrder.indexOf(b.field as keyof T),
      )
      .push(...customColumns);

    let rows = (
      !keys.includes("id" as unknown as keyof T)
        ? data.map((row, index) => ({ ...row, id: index }))
        : data
    ) as T[];

    if (omitColumns.length) {
      rows = rows.map((row) => {
        omitColumns.forEach((column) => {
          delete row[column];
        });

        return row;
      });
    }

    // check using regex if the array keys contains duration
    const durationKeys = keys.filter(
      (key) => typeof key === "string" && /duration/i.test(key),
    );

    if (durationKeys) {
      rows = rows.map((row) => {
        durationKeys.forEach((durationKey) => {
          let duration: number;

          const value = row[durationKey];

          if (typeof value === "string") {
            const v = parseInt(value, 10);
            if (isNaN(v)) {
              console.warn(
                `Duration value at the key of ${durationKey.toString()} is not a number`,
              );
              return;
            } else duration = convertDurationToMilliseconds ? v * 1000 : v;
          } else if (typeof value === "number") {
            duration = convertDurationToMilliseconds // check if the duration is in seconds or milliseconds
              ? value * 1000
              : value;
          } else {
            throw new Error(
              `Duration value at the key of ${durationKey.toString()} is not a string nor a number but is instead of type ${typeof value}`,
            );
          }

          const formattedDuration = TimeHelpers.parseDurationToString({
            duration,
          });

          row = { ...row, [durationKey]: formattedDuration };
        });

        return row;
      });
    }

    return {
      columns,
      rows,
    };
  }
}
