import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  DialogActions,
  Button,
  Stack,
  TextField,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { Fragment, useState } from "react";
import {
  ReactFlow,
  Controls,
  Background,
  BackgroundVariant,
  ReactFlowProvider,
  applyNodeChanges,
  Panel,
} from "reactflow";
import { getNodeWidth } from "../../../utils/formulas";
import { useNodeConfiguration } from "../hooks/useNodeConfiguration";
import { machineNodeTypes } from "../custom-nodes/ModalEditorNode";
import {
  NodeEditorContextProvider,
  useNodeEditorContext,
} from "../context/NodeEditorContextProvider";
import { useLineEditorDispatch } from "../context/LineEditorContextProvider";
import { v4 as uuidv4 } from "uuid";

export const NodeEditorModal = (
  props: React.ComponentProps<typeof Dialog> & { close: () => void },
) => {
  const { close, ...rest } = props;
  return (
    <Dialog {...rest} maxWidth="lg">
      <NodeEditorContextProvider>
        <NodeEditor close={close} />
      </NodeEditorContextProvider>
    </Dialog>
  );
};

const NodeEditor = ({ close }: { close: () => void }) => {
  const { name, isPlaceholder, renderConfiguration } = useNodeConfiguration();

  const [dimension, setDimension] = useState(1);

  const dispatch = useLineEditorDispatch();
  const { handles } = useNodeEditorContext();

  /**
   * Removed id with name to avoid duplicate ids,
   * this needs to be checked later
   */
  const node = {
    id: uuidv4(),
    data: {
      name: name,
      isPlaceholder,
      handles,
    },
    position: { x: 0, y: 0 },
    draggable: true,
    type: "machine",
    style: { width: getNodeWidth(dimension) },
  };

  const saveNodeHandler = () => {
    dispatch({ type: "add node", node: node });
    close();
  };

  return (
    <Fragment>
      <DialogTitle>Node editor</DialogTitle>

      <DialogContent>
        <Stack gap={2}>
          {renderConfiguration()}
          <Box
            sx={{
              height: 400,
              border: "1px solid rgba(255,255,255,.3)",
              borderRadius: 1,
              boxShadow: "1px 1px 1px 0px black",
            }}
          >
            {name ? (
              <ReactFlowProvider>
                <ReactFlow
                  proOptions={{
                    hideAttribution: true,
                  }}
                  fitView
                  nodeTypes={machineNodeTypes}
                  nodes={[node]}
                  onNodesChange={(_nodes) => applyNodeChanges(_nodes, [node])}
                >
                  <Controls showInteractive={false} />
                  <Background
                    lineWidth={1}
                    color="#0000000f"
                    variant={BackgroundVariant.Lines}
                  />
                  <Panel position="top-left">
                    <TextField
                      type="number"
                      size="small"
                      value={dimension}
                      fullWidth
                      InputProps={{
                        inputProps: {
                          max: 5,
                          min: 1,
                        },
                      }}
                      onChange={(e) => setDimension(+e.target.value)}
                      label="width"
                    />
                  </Panel>
                </ReactFlow>
              </ReactFlowProvider>
            ) : null}
          </Box>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          color="error"
          autoFocus
          onClick={() => close()}
        >
          Cancel
        </Button>
        <Button variant="contained" color="success" onClick={saveNodeHandler}>
          Add
        </Button>
      </DialogActions>
    </Fragment>
  );
};

export const UpdateNodeEditor = ({
  close,
  nodeId,
  nodeName,
  isPlaceholder,
  position,
}: {
  close: () => void;
  nodeId: string;
  nodeName: string;
  isPlaceholder: boolean;
  position: { x: number; y: number };
}) => {
  const machines = ["SL1053", "SN1027", "900EEF1195", "900EEF1196"];

  const [name, setName] = useState(nodeName);
  const { handles } = useNodeEditorContext();

  // const { name, isPlaceholder, renderConfiguration } = useNodeConfiguration();

  const [dimension, setDimension] = useState(1);

  const dispatch = useLineEditorDispatch();

  /**
   * Removed id with name to avoid duplicate ids,
   * this needs to be checked later
   */
  const node = {
    // id: `${uuidv4()}-${name}`,
    id: nodeId,
    data: {
      name: name,
      isPlaceholder,
      handles,
    },
    position,
    draggable: true,
    type: "machine",
    style: { width: getNodeWidth(dimension) },
  };

  const updateNodeHandler = () => {
    /**
     * todo: dispatch update node
     */
    dispatch({ type: "edit node", node: node });
    close();
  };

  return (
    <Fragment>
      <DialogTitle>Node editor</DialogTitle>

      <DialogContent>
        <Stack gap={2}>
          {isPlaceholder ? (
            <TextField
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setName(event.target.value);
              }}
              placeholder="Insert the name of the placeholder node"
              fullWidth
              error={name === ""}
              helperText={name === "" ? "Please insert a name" : ""}
            />
          ) : (
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="machine-list">Machines</InputLabel>
              <Select
                labelId="machine-list"
                id="machine-list-helper"
                value={name}
                label="Machine"
                onChange={(event) => setName(event.target.value)}
                error={name === ""}
              >
                {machines.map((machine) => (
                  <MenuItem key={machine} value={machine}>
                    {machine}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                Please select the machine you want to add
              </FormHelperText>
            </FormControl>
          )}
          <Box
            sx={{
              height: 400,
              border: "1px solid rgba(255,255,255,.3)",
              borderRadius: 1,
              boxShadow: "1px 1px 1px 0px black",
            }}
          >
            <ReactFlowProvider>
              <ReactFlow
                proOptions={{
                  hideAttribution: true,
                }}
                fitView
                nodeTypes={machineNodeTypes}
                nodes={[node]}
                onNodesChange={(_nodes) => applyNodeChanges(_nodes, [node])}
              >
                <Controls showInteractive={false} />
                <Background
                  lineWidth={1}
                  color="#0000000f"
                  variant={BackgroundVariant.Lines}
                />
                <Panel position="top-left">
                  <TextField
                    type="number"
                    size="small"
                    value={dimension}
                    fullWidth
                    InputProps={{
                      inputProps: {
                        max: 5,
                        min: 1,
                      },
                    }}
                    onChange={(e) => setDimension(+e.target.value)}
                    label="width"
                  />
                </Panel>
              </ReactFlow>
            </ReactFlowProvider>
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="error"
          autoFocus
          onClick={() => close()}
        >
          Cancel
        </Button>
        <Button variant="contained" color="success" onClick={updateNodeHandler}>
          Add
        </Button>
      </DialogActions>
    </Fragment>
  );
};
