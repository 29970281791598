import { MouseEvent } from "react";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useGetBatchesAndRecipes } from "../api/useGetBatchesAndRecipes";
import {
  Timelapse,
  useMotorsContext,
  useMotorsDispatchContext,
} from "../context/MotorsContextProvider";
import { TimelapseSelection } from "./TimelapseSelection";
import { useTranslate } from "@/i18n/config";

export const ListWithFavorites = () => {
  const { timelapse, recipesFavorites, batchesFavorites } = useMotorsContext();
  const dispatch = useMotorsDispatchContext();

  const { data, isLoading, error } = useGetBatchesAndRecipes();
  const translate = useTranslate();

  if (isLoading) {
    return (
      <>
        <ToggleButtonGroup value="recipes" size="small">
          <ToggleButton value="recipes" aria-label="recipes list">
            {translate("machine.recipe_other")}
          </ToggleButton>
          <ToggleButton value="batches" aria-label="batches list">
            {translate("analytics.batch_other")}
          </ToggleButton>
        </ToggleButtonGroup>
        <TimelapseSelection
          data={[]}
          timelapse="Recipes"
          favorites={[]}
          addToFavorites={() => {}}
          removeFromFavorites={() => {}}
          clearFavorites={() => {}}
        />
      </>
    );
  }

  if (error) {
    return <div>{translate("user_feedback.an_error_occurred")}</div>;
  }

  if (!data) {
    return <div>{translate("user_feedback.no_data")}</div>;
  }

  const handleTimelapse = (
    _: MouseEvent<HTMLElement>,
    newTimelapse: Timelapse,
  ) => {
    if (newTimelapse !== null) {
      dispatch({ type: "SET_TIMELAPSE", timelapse: newTimelapse });
    }
  };

  return (
    <>
      <ToggleButtonGroup
        value={timelapse}
        exclusive
        onChange={handleTimelapse}
        aria-label="timelapse selection"
        size="small"
      >
        <ToggleButton value="recipes" aria-label="recipes list">
          {translate("machine.recipe_other")}
        </ToggleButton>
        <ToggleButton value="batches" aria-label="batches list">
          {translate("analytics.batch_other")}
        </ToggleButton>
      </ToggleButtonGroup>
      {timelapse === "recipes" && (
        <TimelapseSelection
          data={data.items.recipes}
          timelapse={translate("machine.recipe_other")}
          favorites={recipesFavorites}
          addToFavorites={(item) => {
            dispatch({ type: "ADD_RECIPE", item });
          }}
          removeFromFavorites={(item) => {
            dispatch({ type: "REMOVE_RECIPE", item });
          }}
          clearFavorites={() => {
            dispatch({ type: "CLEAR_RECIPES" });
          }}
        />
      )}
      {timelapse === "batches" && (
        <TimelapseSelection
          data={data.items.batches}
          timelapse={translate("analytics.batch_other")}
          favorites={batchesFavorites}
          addToFavorites={(item) => {
            dispatch({ type: "ADD_BATCH", item });
          }}
          removeFromFavorites={(item) => {
            dispatch({ type: "REMOVE_BATCH", item });
          }}
          clearFavorites={() => {
            dispatch({ type: "CLEAR_BATCHES" });
          }}
        />
      )}
    </>
  );
};
