import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { useMotorsContext } from "../context/MotorsContextProvider";
import { ContextInfo, Frame } from "../types";
import { z } from "zod";

type ConditionMonitoringDataResponse =
  | ThingworxError
  | ConditionMonitoringDataResult;

const profilingStatus = z.union([
  z.literal("good"),
  z.literal("minimum warning"),
  z.literal("minimum critical"),
  z.literal("maximum warning"),
  z.literal("maximum critical"),
]);

export type ProfilingStatus = z.infer<typeof profilingStatus>;

const thresholdSchema = z.record(z.number());

const dataSchema1 = z.array(
  z.object({
    metric: z.number(),
    value: z.number(),
    threshold: thresholdSchema,
  }),
);
export type DataSchema1 = z.infer<typeof dataSchema1>;

const dataSchema2 = z.array(
  z.object({
    metric: z.number(),
    minimum: z.number(),
    mean: z.number(),
    maximum: z.number(),
    minimumStatus: profilingStatus,
    meanStatus: profilingStatus,
    maximumStatus: profilingStatus,
  }),
);

export type DataSchema2 = z.infer<typeof dataSchema2>;

const resSchema = z.union([
  z.object({
    analyticName: z.literal("Current Percentage Change"), //? 1
    motorName: z.string(),
    frameName: z.string(),
    chartType: z.literal("line with limits"),
    metricType: z.literal("timestamp"),
    data: dataSchema1,
    contextInfo: ContextInfo,
  }),
  z.object({
    analyticName: z.literal("Current Profiling"), //? 2
    motorName: z.string(),
    frameName: z.string(),
    chartType: z.literal("scatter"),
    metricType: z.literal("timestamp"),
    data: dataSchema2,
    contextInfo: ContextInfo,
  }),
  z.object({
    analyticName: z.literal("Current Cycle Profiling"), //? 3 (manca)
    motorName: z.string(),
    frameName: z.string(),
    chartType: z.literal("area range with line"), //! da fare
    metricType: z.literal("timestamp"), //! da fare
    data: dataSchema2, //! da fare
    contextInfo: ContextInfo,
  }),
  z.object({
    analyticName: z.literal("Cycle Duration"), //? 4 (manca)
    motorName: z.string(),
    frameName: z.string(),
    chartType: z.literal("histogram"),
    metricType: z.literal("degree"), //! da fare
    data: dataSchema2, //! da fare
    contextInfo: ContextInfo,
  }),
  z.object({
    analyticName: z.literal("Cycle RMS"), //? 5
    motorName: z.string(),
    frameName: z.string(),
    chartType: z.literal("line"),
    metricType: z.literal("timestamp"),
    data: z.array(z.array(z.number())),
    contextInfo: ContextInfo,
  }),
  z.object({
    analyticName: z.literal("Average Cycle Current"), //? 6
    motorName: z.string(),
    frameName: z.string(),
    chartType: z.literal("line"),
    metricType: z.literal("timestamp"),
    data: z.array(z.array(z.number())),
    contextInfo: ContextInfo,
  }),
  z.object({
    analyticName: z.literal("RMS vs Master Position"), //? 7
    motorName: z.string(),
    frameName: z.string(),
    chartType: z.literal("scatter"),
    metricType: z.literal("degree"),
    data: z.array(z.array(z.number())),
    contextInfo: ContextInfo,
  }),
  z.object({
    analyticName: z.literal("RMS Percentage Change"), //? 8
    motorName: z.string(),
    frameName: z.string(),
    chartType: z.literal("line with limits"),
    metricType: z.literal("timestamp"),
    data: dataSchema1,
    contextInfo: ContextInfo,
  }),
]);

export type ResSchema = z.infer<typeof resSchema>;

const ConditionMonitoringDataResult = z.object({
  response: z.literal(true),
  analytics: z.array(resSchema),
});

export type ConditionMonitoringDataResult = z.infer<
  typeof ConditionMonitoringDataResult
>;

interface Payload {
  machineName: string;
  framesList: Frame[];
  motorsList: number[];
  analyticsList: string[];
}

export const useGetConditionMonitoringData = ({
  analytics,
}: {
  analytics: string[];
}) => {
  const { machine } = useMachineContext();
  const { frames, motors } = useMotorsContext();
  const { appKey } = useFirebaseContext();

  return useQuery<ResSchema[]>({
    queryKey: [
      "condition-monitoring-data",
      machine?.machine,
      JSON.stringify(frames),
      JSON.stringify(motors),
      JSON.stringify(analytics),
    ],
    queryFn: async () => {
      const response = await FetchClient<
        Payload,
        ConditionMonitoringDataResponse
      >({
        appKey,
        payload: {
          machineName: machine?.machine || "",
          framesList: frames,
          motorsList: motors,
          analyticsList: analytics,
        },
        url: "dig.c.motorConditionMonitoring_thing/Services/getConditionMonitoringData",
      });

      if (!response.response) throw new Error(response.errorString);

      const validatedResponse = ConditionMonitoringDataResult.parse(response);
      return validatedResponse.analytics;
    },
  });
};
