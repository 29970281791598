import { Card } from "@/components/Layout/Card";
import { Button, Stack, TextField, Typography } from "@mui/material";
import { UserRole } from "@/context/firebase-context";
import { useIsAllowed } from "@/hooks/useIsAllowed";
import { Controller, useForm } from "react-hook-form";
import { useSetOeeRange } from "@/features/Download/api/useSetOeeRange";
import { useTranslate } from "@/i18n/config";

export interface Ranges {
  maxBad: number;
  maxMedium: number;
}

export const RangeOeeInput = ({ min, max }: { min: number; max: number }) => {
  const userHasPermission = useIsAllowed([
    UserRole.SUPER_USER_SENTINEL,
    UserRole.SUPER_USER,
    UserRole.USER_ADMIN,
  ]);
  const translate = useTranslate();

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm<Ranges>({
    defaultValues: {
      maxMedium: max,
      maxBad: min,
    },
  });

  const lower_bound = watch("maxBad");

  const { mutate: setRange, isLoading } = useSetOeeRange();

  return (
    <Card>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        gap={2}
        noValidate
        component="form"
        onSubmit={handleSubmit((data) => {
          console.log(data);
          setRange({
            lowerBound: data.maxBad,
            upperBound: data.maxMedium,
          });
        })}
      >
        <Typography>
          {translate("kpi.oee")}{" "}
          <span style={{ color: "red" }}>{translate("bad")} </span>
          {translate("download.from_0_to")}
        </Typography>
        <Controller
          control={control}
          name="maxBad"
          render={({ field }) => (
            <TextField
              {...field}
              disabled={!userHasPermission}
              InputProps={{ inputProps: { min: 0, sx: { padding: 0.5 } } }}
              sx={{ width: "5rem" }}
              type="number"
              helperText={errors.maxBad && errors.maxBad.message}
            />
          )}
        />
        <Typography>
          {translate("kpi.oee")}{" "}
          <span style={{ color: "orange" }}>{`${translate("medium")} `}</span>
          {translate("download.from_amount_to", {
            amount: lower_bound,
          })}
        </Typography>
        <Controller
          control={control}
          name="maxMedium"
          render={({ field }) => (
            <TextField
              {...field}
              disabled={!userHasPermission}
              InputProps={{ inputProps: { min: 0, sx: { padding: 0.5 } } }}
              sx={{ width: "5rem" }}
              type="number"
              helperText={errors.maxMedium && errors.maxMedium.message}
            />
          )}
        />
        <Button
          disabled={!userHasPermission || isLoading}
          type="submit"
          variant="text"
          sx={{
            backgroundColor: "#414957",
            ":hover": { backgroundColor: "#979797" },
          }}
        >
          {translate("actions.save")}
        </Button>
      </Stack>
    </Card>
  );
};
