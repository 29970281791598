import { useState } from "react";
import { BasicTable } from "@/components/BasicTable";
import { RangePicker } from "@/components/Calendars/RangePicker";
import { XRangeChart } from "@/components/highcharts/xrange/XRange";
import { Card } from "@/components/Layout/Card";
import { MoveDateButton } from "@/components/MoveDateButton";
import { MachineViewNavBar } from "@/pages/MachineView/layout/MachineViewNavBar/MachineViewNavBar";
import { TableParser } from "@/utils/TableParser";
import { Box, Stack, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import {
  useGetStateLogData,
  StateLogArrayProps,
  ArrayFilteredProps,
} from "./api/useGetStateLogData";
import { StateListDropDown } from "./components/StateListDropDown";
import { useFilteredStates } from "./store/states-list-store";
import { SkeletonCard } from "@/components/Layout/SkeletonCard";
import { DataTransformer } from "@/utils/DataTransformer";
import { XrangePointOptionsObject } from "highcharts/highstock";
import { useTranslate } from "@/i18n/config";

export const StateLog = () => {
  const translate = useTranslate();
  const [dateRange, setDateRange] = useState<{
    min: number;
    max: number;
  } | null>(null);
  const { data, isLoading, error } = useGetStateLogData();

  if (isLoading) {
    return (
      <>
        <MachineViewNavBar>
          <RangePicker onPickDate={() => {}} />
        </MachineViewNavBar>
        <Stack gap={2}>
          <SkeletonCard height={250}></SkeletonCard>
          <SkeletonCard height={250}></SkeletonCard>
        </Stack>
      </>
    );
  }

  if (error) {
    return <div>{translate("user_feedback.an_error_occurred")}</div>;
  }

  if (!data) {
    return <div>{translate("user_feedback.no_data")}</div>;
  }

  const handleSetExtremes = ({ min, max }: { min: number; max: number }) => {
    setDateRange({ min, max });
  };

  return (
    <>
      <MachineViewNavBar>
        <RangePicker onPickDate={() => {}} />
        <StateListDropDown
          statesList={data.statesList}
          statesOccurred={data.statesOccurred}
        />
      </MachineViewNavBar>
      <Stack gap={2}>
        <ChartSection
          stateLogArray={data.stateLogArray}
          onSetExtremes={handleSetExtremes}
        />
        <TableSection arrayFiltered={data.arrayFiltered} extremes={dateRange} />
      </Stack>
    </>
  );
};

const ChartSection = ({
  stateLogArray,
  onSetExtremes,
}: {
  stateLogArray: StateLogArrayProps[];
  onSetExtremes: ({ min, max }: { min: number; max: number }) => void;
}) => {
  const translate = useTranslate();
  const { filteredStates } = useFilteredStates();
  const dataFiltered = mapXrangeData({
    rawData: stateLogArray,
    filteredStates,
  });
  return (
    <Card>
      <Grid2 container alignItems="center" justifyContent="space-between">
        <MoveDateButton
          action="prevDay"
          text={translate("state_log.previous_day")}
        />
        <Typography>{translate("state_log.machine_states_log")}</Typography>
        <MoveDateButton
          action="nextDay"
          text={translate("state_log.next_day")}
        />
      </Grid2>
      {filteredStates.length > 0 ? (
        <XRangeChart.Custom
          categories={filteredStates}
          data={dataFiltered}
          title=""
          xAxisOptions={{
            events: {
              afterSetExtremes: (e) => {
                onSetExtremes({ min: e.min, max: e.max });
              },
            },
          }}
        />
      ) : (
        <Box
          bgcolor={"#141E30"}
          marginY={2}
          border={"1px solid rgba(81, 81, 81, 1)"}
        >
          <Typography textAlign="center" padding={5}>
            {translate("state_log.no_state_selected")}
          </Typography>
        </Box>
      )}
    </Card>
  );
};

const TableSection = ({
  arrayFiltered,
  extremes,
}: {
  arrayFiltered: ArrayFilteredProps[];
  extremes: { min: number; max: number } | null;
}) => {
  const translate = useTranslate();
  const { filteredStates } = useFilteredStates();
  const filteredData = arrayFiltered.filter((value) => {
    if (extremes) {
      return (
        filteredStates.includes(value.stateString) &&
        value.timestamp >= extremes.min &&
        value.timestampAfter <= extremes.max
      );
    } else {
      return filteredStates.includes(value.stateString);
    }
  });
  const { columns, rows } =
    filteredData.length > 0
      ? TableParser.parseData<ArrayFilteredProps>({
          data: filteredData.map((item) =>
            DataTransformer.dataTransformer({
              input: {
                ...item,
                recipe: item.recipe === "INDEFINITO" ? "Empty" : item.recipe,
                error:
                  !item.stateString.toLowerCase().includes("blocking alarm") &&
                  item.stateString !== "Error"
                    ? ""
                    : item.error,
                errorDescription:
                  !item.stateString.toLowerCase().includes("blocking alarm") &&
                  item.stateString !== "Error"
                    ? ""
                    : item.errorDescription,
              },
              transform: {},
            }),
          ),
          columnsMap: {
            duration: translate("duration"),
            error: translate("error_code"),
            errorDescription: translate("error_description"),
            recipe: translate("machine.recipe"),
            stateString: translate("state"),
            stateColor: "State Color",
            timestampAfter: "Timestamp End",
            timestamp: translate("timestamp"),
          },
          columnsOrder: [
            "timestamp",
            "stateString",
            "duration",
            "recipe",
            "error",
            "errorDescription",
          ],
          omitColumns: ["stateColor", "timestampAfter"],
        })
      : {
          columns: [],
          rows: [],
        };

  return (
    <Card>
      <BasicTable columns={columns} rows={rows} fileName="state_log_data" />
    </Card>
  );
};

function mapXrangeData({
  rawData,
  filteredStates,
}: {
  rawData: StateLogArrayProps[];
  filteredStates: string[];
}) {
  const transformedData: XrangePointOptionsObject[] = rawData.reduce<
    XrangePointOptionsObject[]
  >((acc: XrangePointOptionsObject[], item) => {
    // per mantenere l'ordine della posizione degli indici come nel dropDown ricavo l'index da statesList
    const index = filteredStates.indexOf(item.state);
    if (index !== -1) {
      acc.push({
        name: item.state,
        x: item.start,
        x2: item.end,
        y: index,
        color: item.color,
      });
    }
    return acc;
  }, []);
  return transformedData;
}
