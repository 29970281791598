import { Star, StarOutline } from "@mui/icons-material";
import {
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  ListItemText,
  ListItemButton,
} from "@mui/material";
import { Item } from "../context/MotorsContextProvider";
import { useTranslate } from "@/i18n/config";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 6.5 + ITEM_PADDING_TOP,
      width: 400,
    },
  },
};

interface TimelapseSelectionProps {
  timelapse: string;
  data: Item[];
  favorites: Item[];
  addToFavorites: (item: Item) => void;
  removeFromFavorites: (item: Item) => void;
  clearFavorites: () => void;
}

export const TimelapseSelection = ({
  timelapse,
  data = [],
  favorites,
  addToFavorites,
  removeFromFavorites,
  clearFavorites,
}: TimelapseSelectionProps) => {
  const translate = useTranslate();
  const oldFavorites = favorites.filter((el) => {
    return !data.some(
      (item) =>
        item.id === el.id &&
        item.dateStart === el.dateStart &&
        item.dateEnd === el.dateEnd,
    );
  });

  return (
    <FormControl sx={{ width: "140px" }} size="small">
      <InputLabel id={`multiple-${timelapse}-label`}>{timelapse}</InputLabel>
      <Select
        labelId={`multiple-${timelapse}-label`}
        id={`multiple-${timelapse}`}
        multiple
        value={[]}
        onChange={() => {}}
        input={<OutlinedInput label={timelapse} />}
        MenuProps={MenuProps}
      >
        <ListItemButton component="a" onClick={() => clearFavorites()}>
          <ListItemText primary={translate("actions.clear_all")} />
        </ListItemButton>
        {oldFavorites.map((item) => (
          <ListItemButton
            key={`${item.id}-${item.timelapse}-${item.dateStart}-${item.dateEnd}`}
            onClick={() => removeFromFavorites(item)}
          >
            <ListItemText
              primary={`${item.timelapse} - ${new Date(
                item.dateStart,
              ).toLocaleString("en-US")}`}
            />
            <Star />
          </ListItemButton>
        ))}
        {data.map((item) => {
          return favorites.find(
            (obj) =>
              obj.id === item.id &&
              obj.dateStart === item.dateStart &&
              obj.dateEnd === item.dateEnd,
          ) ? (
            <ListItemButton
              key={`${item.id}-${item.timelapse}-${item.dateStart}-${item.dateEnd}`}
              onClick={() => removeFromFavorites(item)}
            >
              <ListItemText
                primary={`${item.timelapse} - ${new Date(
                  item.dateStart,
                ).toLocaleString("en-US")}`}
              />
              <Star />
            </ListItemButton>
          ) : (
            <ListItemButton
              key={`${item.id}-${item.timelapse}-${item.dateStart}-${item.dateEnd}`}
              onClick={() => addToFavorites(item)}
            >
              <ListItemText
                primary={`${item.timelapse} - ${new Date(
                  item.dateStart,
                ).toLocaleString("en-US")}`}
              />
              <StarOutline />
            </ListItemButton>
          );
        })}
      </Select>
    </FormControl>
  );
};
