import { Box } from "@mui/material";
import { DescriptiveAnalytics } from "../components/Monitoring/DescriptiveAnalytics";
import { DescriptiveAnalyticsSelection } from "../components/Monitoring/DescriptiveAnalyticsSelection";
import { useState } from "react";

export const Descriptive = () => {
  const [selectedAnalytics, setSelectedAnalytics] = useState<string[]>([]);

  return (
    <Box sx={{ display: "flex", gap: 1 }}>
      <DescriptiveAnalyticsSelection
        setSelectedAnalytics={setSelectedAnalytics}
      />
      <DescriptiveAnalytics analytics={selectedAnalytics} />
    </Box>
  );
};
