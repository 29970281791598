import { useFirebaseContext } from "@/context/firebase-context";
import { FetchClient } from "@/services/ApiClient";
import { useTimeSelection, TimeSelection } from "@/store/useTimeSelection";
import { QueryBuilder } from "@/utils/query-builder";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { z } from "zod";

type Payload =
  | {
      config: "automatic";
      timeSelection: TimeSelection;
      line_id: number;
      config_id: number;
    }
  | {
      config: "manual";
      line_id: number;
      config_id: number;
    };

type LineProductsProjectionResponse =
  | ThingworxError
  | LineProductsProjectionResult;

const LineProductsProjectionResult = z.object({
  response: z.literal(true),
  products: z.array(z.array(z.number())),
  products_projection: z.array(z.array(z.number())),
  machine_output: z.string(),
});

type LineProductsProjectionResult = z.infer<
  typeof LineProductsProjectionResult
>;

export const useGetLineProductsProjection = ({
  config,
  line_id,
  config_id,
}: {
  config: "automatic" | "manual";
  line_id: number;
  config_id: number;
}) => {
  const { appKey } = useFirebaseContext();
  const { timeSelection } = useTimeSelection();

  const queryKey = QueryBuilder.buildWithCondition({
    baseQuery: ["line-products-projection", line_id, config_id, config],
    condition: config === "automatic",
    query: [timeSelection],
  });

  const payload: Payload =
    config === "automatic"
      ? {
          config,
          timeSelection,
          line_id,
          config_id,
        }
      : {
          config,
          line_id,
          config_id,
        };

  return useQuery({
    queryKey,
    queryFn: async () => {
      const response = await FetchClient<
        Payload,
        LineProductsProjectionResponse
      >({
        appKey,
        payload,
        url: "dig.c.plantOverview_thing/Services/getLineProductsProjection",
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      return LineProductsProjectionResult.parse(response);
    },
  });
};
