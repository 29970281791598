import { SelectTimeSelection } from "@/components/SelectTimeSelection";
import { MachineViewNavBar } from "@/pages/MachineView/layout/MachineViewNavBar/MachineViewNavBar";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { useTimeSelection } from "@/store/useTimeSelection";
import {
  AlarmsTableData,
  useGetAlarmsTableData,
} from "./api/useGetAlarmsTableData";
import { BasicTable } from "@/components/BasicTable";
import { TableParser } from "@/utils/TableParser";
import { AnalyticContainerSkeleton } from "../Diagnostic/pages/LYO/components/AnalyticContainer/AnalyticContainerLayout";
import { GridActionsCellItem } from "@mui/x-data-grid-premium";
import { QueryStats } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import {
  AlarmData,
  TransformedAlarmData,
  useGetAlarmsColumnData,
} from "./api/useGetAlarmsColumnData";
import { useState } from "react";
import { DataTransformer } from "@/utils/DataTransformer";
import { ShowEmptyData } from "@/components/ShowEmptyData";
import { BarChart } from "@/components/highcharts/barchart/Barchart";
import { renderToString } from "react-dom/server";
import { TimeHelpers } from "@/utils/TimeHelpers";
import { Card } from "@/components/Layout/Card";
import { DateTime } from "luxon";
import { useCheckMachinePage } from "@/hooks/check-machine-page";
import { useTranslate } from "@/i18n/config";
import { useMachineContext } from "@/context/machine-context";
import { fileNameFactory } from "@/utils/fileNameFactory";

const SECTION_NAMES = {
  first: 1,
  second: 2,
};

export const Alarms = () => {
  const [section] = useState(SECTION_NAMES.first);

  return (
    <Box>
      <MachineViewNavBar>
        <SelectTimeSelection />
      </MachineViewNavBar>
      {/* <Tabs
        value={section}
        aria-label="batch detail tabs"
        onChange={(_: React.SyntheticEvent, newValue: number) => {
          setSection(newValue);
        }}>
        <Tab value={SECTION_NAMES.first} label="Default" {...a11yProps(0)} />
        <Tab value={SECTION_NAMES.second} label="Advanced" {...a11yProps(1)} />
      </Tabs> */}

      {section === SECTION_NAMES.first ? (
        <StandardAlarmView />
      ) : section === SECTION_NAMES.second ? (
        <AdvancedAlarms />
      ) : null}
    </Box>
  );
};

function AdvancedAlarms() {
  return <div>some custom view here</div>;
}

function StandardAlarmView() {
  const {
    data: streamData,
    isLoading: streamDataLoading,
    isError: streamDataError,
  } = useGetAlarmsTableData();

  const {
    data: columnData,
    isLoading: columnDataLoading,
    isError: columnDataError,
  } = useGetAlarmsColumnData();

  const translate = useTranslate();

  return (
    <Stack gap={2}>
      {columnData ? (
        <>
          <AlarmColumns alarmData={columnData.Result} />
          <StreamTableContent data={columnData.Result} />
        </>
      ) : columnDataLoading ? (
        <AnalyticContainerSkeleton />
      ) : columnDataError ? (
        <Card>{translate("user_feedback.an_error_occurred")}</Card>
      ) : null}
      {streamData ? (
        <TableContent data={streamData.array} />
      ) : streamDataLoading ? (
        <AnalyticContainerSkeleton />
      ) : streamDataError ? (
        <Card>{translate("user_feedback.an_error_occurred")}</Card>
      ) : null}
    </Stack>
  );
}

export function AlarmColumns({ alarmData }: { alarmData: AlarmData[] }) {
  const [sortBy, setSortBy] = useState<"count" | "duration">("count");
  const translate = useTranslate();

  const { machine } = useMachineContext();
  const machineName = machine?.machine;

  const { timeSelection } = useTimeSelection();

  const data = alarmData.sort((a, b) => {
    if (sortBy === "count") {
      return b.Count - a.Count;
    } else {
      return b.Duration - a.Duration;
    }
  });

  const alarmCount = data.map((x) => ({
    y: x.CountPercentage,
    custom: {
      duration: TimeHelpers.parseDurationToString({
        duration: x.Duration * 1000,
      }),
      mttr: TimeHelpers.parseDurationToString({ duration: x.MTTR * 1000 }),
      mtbf: TimeHelpers.parseDurationToString({ duration: x.MTBF * 1000 }),
      description: x.ErrorDescription,
      count: x.Count,
    },
  }));
  const alarmDuration = alarmData.map((x) => x.DurationPercentage);
  const categories = alarmData.map((x) => x.Error.toString());

  return (
    <Card>
      <BarChart.Custom
        yAxisOptions={{ max: 100 }}
        showLogarithmicToggle={true}
        categories={categories}
        filename={fileNameFactory({
          machine: machineName,
          name: "alarms",
          date: timeSelection,
        })}
        title={translate("alarms.count_duration_percentage")}
        uom="%"
        legend={{
          enabled: true,
        }}
        tooltip={{
          enabled: true,
          options: {
            useHTML: true,

            formatter: function () {
              /**
               * this is a ts hack to get the custom property working,
               * in this case they would be inside only the first point
               */

              const point = this.points?.[0].point as unknown as {
                custom: {
                  duration: string;
                  mttr: string;
                  mtbf: string;
                  description: string;
                  count: number;
                };
              };

              const count = this.points?.[0];
              const duration = this.points?.[1];

              if (point) {
                return renderToString(
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <b>
                      {translate("count")} %: {count ? count.y?.toFixed(2) : 0}%
                    </b>
                    <b>
                      {translate("count")}: {point.custom.count}
                    </b>
                    <b>
                      {translate("duration_ratio")}:{" "}
                      {duration ? duration.y?.toFixed(2) : 0}%
                    </b>
                    <b>
                      {translate("duration")}: {point.custom.duration}
                    </b>
                    <b>
                      {translate("mttr")}: {point.custom.mttr}
                    </b>
                    <b>
                      {translate("mtbf")}: {point.custom.mtbf}
                    </b>
                    <b>
                      {translate("description")}: {point.custom.description}
                    </b>
                  </div>,
                );
              }
            },
          },
        }}
        series={[
          {
            type: "column",
            name: translate("count"),
            data: alarmCount,
            color: "#F07802",
          },
          {
            type: "column",
            name: translate("duration"),
            data: alarmDuration,
            color: "#F4C604",
          },
        ]}
      />
      <FormControl>
        <FormLabel id="demo-row-radio-buttons-group-label">
          {translate("sort_by")}
        </FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={sortBy}
          onChange={(e) => setSortBy(e.target.value as "count" | "duration")}
        >
          <FormControlLabel
            value="count"
            control={<Radio />}
            label={translate("count")}
          />
          <FormControlLabel
            value="duration"
            control={<Radio />}
            label={translate("duration")}
          />
        </RadioGroup>
      </FormControl>
    </Card>
  );
}

function TableContent({ data }: { data: AlarmsTableData[] }) {
  const { hasAccess } = useCheckMachinePage("stateLog");
  const translate = useTranslate();
  const navigate = useNavigate();

  if (!data.length)
    return <ShowEmptyData title={translate("alarms.alarms_detail")} />;

  const { rows, columns } = TableParser.parseData<AlarmsTableData>({
    data: data,
    columnsMap: {
      EfficiencyFamilyDescription: "Efficiency Loss",
      durationms: "duration",
      ErrorDescription: "Alarm Description",
      Error: "Code",
      Family: "family code",
      FamilyDescription: "Family",
      Timestamp: "Timestamp",
      timestampEnd: "End",
      Zone: "zone",
      ZoneDescription: "Alarm Zone",
    },
    columnsOrder: [
      "Error",
      "Timestamp",
      "timestampEnd",
      "durationms",
      "ErrorDescription",
      "ZoneDescription",
      "Zone",
      "FamilyDescription",
      "EfficiencyFamilyDescription",
    ],
    omitColumns: ["Family", "Zone"],
    customColumns: hasAccess
      ? [
          {
            field: "actions",
            type: "actions",
            headerName: "Detail",
            getActions: ({ row }) => {
              return [
                <GridActionsCellItem
                  icon={<QueryStats />}
                  label="Detail"
                  onClick={() => {
                    useTimeSelection.setState((prevState) => ({
                      ...prevState,
                      dates: {
                        dateStart: DateTime.fromMillis(row.Timestamp).minus({
                          minutes: 20,
                        }),
                        dateEnd: DateTime.fromMillis(row.timestampEnd).plus({
                          minutes: 20,
                        }),
                      },
                    }));
                    navigate("/state-log");
                  }}
                />,
              ];
            },
          },
        ]
      : [],
  });

  return (
    <Card>
      <Typography variant="h5">{translate("alarms.alarms_detail")}</Typography>
      <BasicTable columns={columns} rows={rows} fileName="alarms_detail_data" />
    </Card>
  );
}

function StreamTableContent({ data }: { data: AlarmData[] }) {
  const translate = useTranslate();

  if (!data.length)
    return <ShowEmptyData title={translate("alarms.alarms_summary")} />;

  const { rows, columns } = TableParser.parseData<TransformedAlarmData>({
    data: data.map((item) =>
      DataTransformer.dataTransformer<AlarmData, TransformedAlarmData>({
        input: {
          ...item,
          MTTR: item.MTTR * 1000,
          MTBF: item.MTBF * 1000,
          Duration: item.Duration * 1000,
          CountPercentage: Number(item.CountPercentage.toFixed(2)),
          DurationPercentage: Number(item.DurationPercentage.toFixed(2)),
        },
        transform: {
          MTTR: "MTTRduration",
          MTBF: "MTBFduration",
          DurationPercentage: "timeAmountPercentage",
        },
      }),
    ),
    columnsMap: {
      Error: translate("code"),
      ErrorDescription: translate("description"),
      Count: translate("occurrences"),
      CountPercentage: `${translate("occurrences")} %`,
      MTBFduration: translate("mtbf"),
      MTTRduration: translate("mttr"),
      Duration: translate("duration"),
      timeAmountPercentage: `${translate("duration")} %`,
      CumulativeCount: translate("cumulative_occurrences"),
      CumulativeDuration: translate("cumulative_duration"),
      Family: translate("family"),
    },
    columnsOrder: [
      "Error",
      "ErrorDescription",
      "Count",
      "CountPercentage",
      "MTBFduration",
      "MTTRduration",
      "Duration",
      "timeAmountPercentage",
    ],
    omitColumns: ["CumulativeCount", "CumulativeDuration", "Family"],
  });
  return (
    <Card>
      <Typography variant="h5">{translate("alarms.alarms_summary")}</Typography>
      <BasicTable
        columns={columns}
        rows={rows}
        fileName="alarms_summary_data"
      />
    </Card>
  );
}
