import { z } from "zod";

/**
 * @description
 * 1. id: unique id for each edge
 * 2. source: id of the source node
 * 3. target: id of the target node
 */
const Edge = z.object({
  id: z.string(),
  source: z.string(),
  target: z.string(),
  animated: z.boolean().optional(),
  sourceHandle: z.string().nullable(),
  targetHandle: z.string().nullable(),
  type: z.string().optional(),
});

type Edge = z.infer<typeof Edge>;

/**
 * @description
 * 1. enabled: true/false
 * 2. type: source/target
 *
 * defines whether the handle is enabled or not, and if enabled, whether it is a source or target handle
 */
export const HandleConfiguration = z.discriminatedUnion("enabled", [
  z.object({
    enabled: z.literal(true),
    type: z.union([z.literal("source"), z.literal("target")]),
  }),
  z.object({
    enabled: z.literal(false),
  }),
]);

export type HandleConfiguration = z.infer<typeof HandleConfiguration>;

/**
 * @description
 * configurations for all the handles from id 1 to 8
 */
export const Handles = z.object({
  "1": HandleConfiguration,
  "2": HandleConfiguration,
  "3": HandleConfiguration,
  "4": HandleConfiguration,
  "5": HandleConfiguration,
  "6": HandleConfiguration,
  "7": HandleConfiguration,
  "8": HandleConfiguration,
});

export type Handles = z.infer<typeof Handles>;

/**
 * @description
 * 1. name: name of the node, usually the name of the machine or the placeholder
 * 2. isPlaceholder: true/false, defines whether the node is a placeholder or not
 * 3. handles: configurations for all the handles
 */
export const NodeBlockData = z.object({
  name: z.string(),
  isPlaceholder: z.boolean(),
  handles: Handles,
});

export type NodeBlockData = z.infer<typeof NodeBlockData>;

/**
 * @description
 * 1. x: x coordinate of the node
 * 2. y: y coordinate of the node
 */
const Position = z.object({
  x: z.number(),
  y: z.number(),
});

type Position = z.infer<typeof Position>;

/**
 * @description
 * 1. x: x dimension of the viewport
 * 2. y: y dimension of the viewport
 * 3. zoom: zoom level of the viewport
 */
const Viewport = z.object({
  x: z.number(),
  y: z.number(),
  zoom: z.number(),
});

type Viewport = z.infer<typeof Viewport>;

/**
 * @description
 * data needed for each node displayed on the canvas
 */
export const NodeBlock = z.object({
  width: z.number(),
  height: z.number(),
  id: z.string(),
  data: NodeBlockData,
  position: Position,
  selected: z.boolean().optional(),
  positionAbsolute: Position,
  type: z.literal("machine"),
  draggable: z.boolean().optional(),
});

export type NodeBlock = z.infer<typeof NodeBlock>;

/**
 * @description
 * 1. nodes: array of nodes
 * 2. edges: array of edges
 * 3. viewport: viewport data
 *
 * defines the overall configuration of the canvas
 * and that is how the data needs to be saved and restore from the database
 */
export const LineConfig = z.object({
  nodes: z.array(NodeBlock),
  edges: z.array(Edge),
  viewport: Viewport,
});

export type LineConfig = z.infer<typeof LineConfig>;
