import { AnalyticContainerSkeleton } from "@/features/Diagnostic/pages/LYO/components/AnalyticContainer/AnalyticContainerLayout";
import { useGetDescriptiveData } from "../../api/useGetDescriptiveData";
import { DescriptiveAnalytic } from "./DescriptiveAnalytic";
import { Stack, Typography } from "@mui/material";
import { Card } from "@/components/Layout/Card";
import { useMotorsContext } from "../../context/MotorsContextProvider";
import { useTranslate } from "@/i18n/config";

export const DescriptiveAnalytics = ({
  analytics,
}: {
  analytics: string[];
}) => {
  const { data, isLoading, error } = useGetDescriptiveData({ analytics });
  const { frames, motors } = useMotorsContext();
  const translate = useTranslate();

  if (isLoading) {
    return <AnalyticContainerSkeleton width="calc(100% - 320px)" />;
  }

  if (error) {
    return <div>{translate("user_feedback.an_error_occurred")}</div>;
  }

  if (!data) {
    return <div>{translate("user_feedback.no_data")}</div>;
  }

  return (
    <Stack gap={1} width="calc(100% - 320px)">
      {data.length > 0 ? (
        data.map((analytic) => (
          <DescriptiveAnalytic
            key={`${analytic.analyticName}-${analytic.motorName}-${analytic.frameName}`}
            props={analytic}
          />
        ))
      ) : analytics.length > 0 && frames.length > 0 && motors.length > 0 ? (
        <Card sx={{ height: 400 }}>
          <Typography variant="h4">
            {translate("motors.no_data_try_another")}
          </Typography>
          <Typography variant="h6">
            {translate("motors.select_at_least")}
          </Typography>
        </Card>
      ) : (
        <Card sx={{ height: 400 }}>
          <Typography sx={{ color: "red" }} variant="h4">
            {translate("motors.no_selection")}
          </Typography>
          <Typography variant="h6">
            {translate("motors.select_at_least")}
          </Typography>
        </Card>
      )}
    </Stack>
  );
};
