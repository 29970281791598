import { useState } from "react";
import {
  Box,
  Button,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { GridRowOrderChangeParams } from "@mui/x-data-grid-premium";
import { XrangePointOptionsObject } from "highcharts/highstock";
import { Card } from "@/components/Layout/Card";
import { Modal } from "@/components/Modal";
import { BasicTable } from "@/components/BasicTable";
import { XRangeChart } from "@/components/highcharts/xrange/XRange";
import { useDisclosure } from "@/hooks/useDisclosure";
import { TimeHelpers } from "@/utils/TimeHelpers";
import { StatesLogData } from "@/features/OverallLineEfficiency/Pages/LineDetail/api/useGetStatesLog";
import { StatesLogLegend } from "@/features/OverallLineEfficiency/components/StatesLogLegend";
import { z } from "zod";
import { useSetMachinesOrder } from "@/features/OverallLineEfficiency/api/useSetMachinesOrder";
import { useIsAllowed } from "@/hooks/useIsAllowed";
import { UserRole } from "@/context/firebase-context";

interface Row {
  id: number;
  "Machine Name": string;
}

const updateRowPosition = (
  initialIndex: number,
  newIndex: number,
  rows: Row[],
): Promise<Row[]> => {
  return new Promise((resolve) => {
    const rowsClone = [...rows];
    const row = rowsClone.splice(initialIndex, 1)[0];
    rowsClone.splice(newIndex, 0, row);
    resolve(rowsClone);
  });
};

const sortObjectKeys = (
  object: StatesLogData,
  orderArray: string[],
): StatesLogData => {
  const orderedKeys = Object.keys(object).sort((a, b) => {
    const indexA = orderArray.indexOf(a);
    const indexB = orderArray.indexOf(b);
    return indexA - indexB;
  });

  const sortedObject: StatesLogData = {};
  orderedKeys.forEach((key) => {
    sortedObject[key] = object[key];
  });

  return sortedObject;
};

const LegendData = z.record(z.string());

export type LegendData = z.infer<typeof LegendData>;

const parseData = (
  data: StatesLogData,
): {
  machines: string[];
  chartData: XrangePointOptionsObject[];
  legendData: LegendData;
} => {
  const machines = Object.keys(data);
  const chartData: XrangePointOptionsObject[] = [];
  const legendData: LegendData = {};

  machines.forEach((machine, index) => {
    const machineStatesLog = data[machine];
    machineStatesLog.forEach((occurrence) => {
      const { start, end, state, color } = occurrence;
      legendData[state] = color;
      chartData.push({
        name: state,
        x: start,
        x2: end,
        y: index,
        color,
      });
    });
  });

  return { machines, chartData, legendData };
};

export const HistoricLineMachinesStatesLog = ({
  dataBatches,
  order,
}: {
  dataBatches: { [key: string]: StatesLogData };
  order: string[];
}) => {
  const [orderedMachines, setOrderedMachines] = useState<string[]>(order);
  const [selectedBatch, setSelectedBatch] = useState(
    Object.keys(dataBatches)[0],
  );
  const [batchData, setBatchData] = useState(dataBatches[selectedBatch]);
  const { open, close, isOpen } = useDisclosure();
  const { mutate: setMachinesOrder } = useSetMachinesOrder();
  const isAllowed = useIsAllowed([
    UserRole.USER_ADMIN,
    UserRole.SUPER_USER,
    UserRole.SUPER_USER_SENTINEL,
  ]);

  const { machines, chartData, legendData } =
    orderedMachines.length > 0
      ? parseData(sortObjectKeys(batchData, orderedMachines))
      : parseData(batchData);

  const columns = [{ field: "Machine Name" }];
  const rows = machines.map((machine, i) => {
    return { id: i, "Machine Name": machine };
  });

  const handleRowOrderChange = async (params: GridRowOrderChangeParams) => {
    const newRows = await updateRowPosition(
      params.oldIndex,
      params.targetIndex,
      rows,
    );
    setOrderedMachines(newRows.map((row) => row["Machine Name"]));
  };

  const handleSelectBatch = (
    _: React.MouseEvent<HTMLElement>,
    newBatch: string,
  ) => {
    if (!newBatch) return;
    setSelectedBatch(newBatch);
    setBatchData(dataBatches[newBatch]);
  };

  const handleSave = () => {
    setMachinesOrder({
      machinesOrder: orderedMachines,
    });
    close();
  };

  return (
    <Card>
      <Stack>
        {isAllowed && (
          <Button
            variant="outlined"
            sx={{
              width: "fit-content",
            }}
            onClick={open}
          >
            Sort Machines
          </Button>
        )}
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <ToggleButtonGroup
            value={selectedBatch}
            exclusive
            onChange={handleSelectBatch}
            aria-label="Variables"
            sx={{ backgroundColor: "#1F293F" }}
          >
            {Object.keys(dataBatches).map((batch) => (
              <ToggleButton key={batch} value={batch}>
                {batch}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Box>
        <Modal
          open={isOpen}
          onClose={close}
          bodyContent={
            <BasicTable
              columns={columns}
              rows={rows}
              allowRowReordering={true}
              handleRowOrderChange={handleRowOrderChange}
            />
          }
          titleContent="Sort Line Machines"
          actions={
            <>
              <Button variant="outlined" onClick={close}>
                Close
              </Button>
              <Button variant="outlined" onClick={handleSave}>
                Save
              </Button>
            </>
          }
        />
        <XRangeChart.Custom
          title="Line Machines States Log"
          categories={machines}
          data={chartData}
          borderRadius={0}
          tooltipFormatter={function () {
            const data = z
              .object({
                x: z.number(),
                x2: z.number(),
                y: z.number(),
                color: z.string(),
                key: z.string(),
                yCategory: z.string(),
              })
              .safeParse(this);

            if (!data.success) return;

            const dateStart = TimeHelpers.parseTimestampToString({
              timestamp: data.data.x,
            });
            const dateEnd = TimeHelpers.parseTimestampToString({
              timestamp: data.data.x2,
            });
            const color = data.data.color;
            const state = data.data.key;
            const machine = data.data.yCategory;

            return `
            <b>${machine}</b><br>
            Start: ${dateStart}<br>
            End: ${dateEnd}<br>
            <span style="color:${color}">●</span> <b>${state}</b>
          `;
          }}
        ></XRangeChart.Custom>
        <StatesLogLegend legendData={legendData} />
      </Stack>
    </Card>
  );
};
