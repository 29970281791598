import { Box, Button, Stack } from "@mui/material";
import { PropsWithChildren, useCallback, useRef, useState } from "react";
import {
  ReactFlow,
  Controls,
  Background,
  BackgroundVariant,
  Panel,
  ReactFlowProvider,
  ReactFlowInstance,
  addEdge,
  applyNodeChanges,
  applyEdgeChanges,
  updateEdge,
} from "reactflow";
import { useDisclosure } from "@/hooks/useDisclosure";
import { NodeEditorModal } from "./components/NodeEditorModal";

import { machineEditNodeTypes } from "./custom-nodes/LineEditorNode";
import {
  useOeeLineContextDispatch,
  useOeeLineContextState,
} from "../LineOverview/context/oee-line-context";
import {
  LineEditorContextProvider,
  useLineEditorContext,
  useLineEditorDispatch,
} from "./context/LineEditorContextProvider";
import { LineConfig } from "./types";
import { useSetLineConfiguration } from "../../api/useSetLineConfiguration";
import { AddOutlined, CancelOutlined, SaveOutlined } from "@mui/icons-material";

export const Edit = ({
  initialConfig = { nodes: [], edges: [] },
}: PropsWithChildren<{
  initialConfig?: Pick<LineConfig, "edges" | "nodes">;
}>) => {
  const { open, isOpen, close } = useDisclosure(false);

  /**
   * todo: enable edit of an already existing line, maybe by having optional props to initialize the provider
   */
  return (
    <LineEditorContextProvider
      initialConfig={{ edges: initialConfig.edges, nodes: initialConfig.nodes }}
    >
      <LineEditor open={open} />
      <NodeEditorModal open={isOpen} onClose={close} close={close} fullWidth />
    </LineEditorContextProvider>
  );
};

const LineEditor = ({ open }: { open: () => void }) => {
  const edgeUpdateSuccessful = useRef(true);
  const [reactFlowInstance, setReactFlowInstance] =
    useState<ReactFlowInstance | null>();
  const { mutate: saveConfiguration } = useSetLineConfiguration();
  const { nodes, edges } = useLineEditorContext();

  const { selectedLine } = useOeeLineContextState();

  const lineContextDispatch = useOeeLineContextDispatch();

  const dispatch = useLineEditorDispatch();

  const save = useCallback(() => {
    if (reactFlowInstance && selectedLine) {
      const flow = reactFlowInstance.toObject() as LineConfig;

      saveConfiguration({
        line_configuration: flow,
        line_id: selectedLine.line_id,
      });

      lineContextDispatch({ type: "disable edit mode" });
    }
  }, [reactFlowInstance, selectedLine, saveConfiguration, lineContextDispatch]);

  return (
    <Box
      sx={{
        height: 600,
      }}
    >
      <ReactFlowProvider>
        <ReactFlow
          defaultEdgeOptions={{
            type: "smoothstep",
            animated: true,
          }}
          nodeTypes={machineEditNodeTypes}
          fitView
          proOptions={{
            hideAttribution: true,
          }}
          snapToGrid
          style={{
            backgroundColor: "rgba(255, 255, 255, .1)",
          }}
          edges={edges}
          onNodesChange={(changes) =>
            dispatch({
              type: "update nodes",
              nodes: applyNodeChanges(changes, nodes),
            })
          }
          onEdgesChange={(changes) => {
            edgeUpdateSuccessful.current = true;
            dispatch({
              type: "update edges",
              edges: applyEdgeChanges(changes, edges),
            });
          }}
          onConnect={(connection) =>
            dispatch({
              type: "connect edge",
              edges: addEdge(connection, edges),
            })
          }
          nodes={nodes}
          nodesDraggable
          onEdgeUpdate={(oldEdge, newConnection) => {
            edgeUpdateSuccessful.current = true;
            dispatch({
              type: "update edges",
              edges: updateEdge(oldEdge, newConnection, edges),
            });
          }}
          onEdgeUpdateStart={() => (edgeUpdateSuccessful.current = false)}
          onEdgeUpdateEnd={(_, edge) => {
            if (!edgeUpdateSuccessful.current) {
              dispatch({ type: "remove edge", edgeId: edge.id });
              edgeUpdateSuccessful.current = true;
            }
          }}
          onInit={setReactFlowInstance}
        >
          <Controls showInteractive={false} />
          <Background
            lineWidth={1}
            color="#0000000f"
            variant={BackgroundVariant.Lines}
          />
          <Panel position="top-left">
            <Stack direction="row" gap={2}>
              <Button
                variant="outlined"
                startIcon={<AddOutlined />}
                onClick={() => open()}
              >
                Create Node
              </Button>
            </Stack>
          </Panel>
          <Panel position="bottom-right">
            <Stack direction="row" gap={2}>
              <Button
                variant="outlined"
                color="error"
                startIcon={<CancelOutlined />}
                onClick={() =>
                  lineContextDispatch({ type: "disable edit mode" })
                }
              >
                cancel edit
              </Button>
              <Button
                variant="outlined"
                color="success"
                startIcon={<SaveOutlined />}
                onClick={save}
              >
                save
              </Button>
            </Stack>
          </Panel>
        </ReactFlow>
      </ReactFlowProvider>
    </Box>
  );
};
