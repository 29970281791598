import { useMutation, useQueryClient } from "@tanstack/react-query";
import { LineConfig } from "../Pages/Edit/types";
import { FetchClient } from "@/services/ApiClient";
import { useFirebaseContext } from "@/context/firebase-context";
import { useOeeLineContextDispatch } from "../Pages/LineOverview/context/oee-line-context";
import { ThingworxError } from "src/types/error";
import { toast } from "react-hot-toast";
import { useTranslate } from "@/i18n/config";

type Payload = {
  line_id: number;
  line_configuration: LineConfig;
};

export const useSetLineConfiguration = () => {
  const translate = useTranslate();
  const { appKey } = useFirebaseContext();
  const client = useQueryClient();

  const dispatch = useOeeLineContextDispatch();

  return useMutation({
    mutationFn: async ({ line_configuration, line_id }: Payload) => {
      return await FetchClient<Payload, { response: true } | ThingworxError>({
        appKey,
        payload: { line_configuration, line_id },
        url: "dig.c.plantOverview_thing/Services/setLineConfiguration",
      });
    },
    onSuccess: (response, { line_id, line_configuration }) => {
      if (!response.response) {
        toast.error(
          translate("overall_line_efficiency.failed_to_update_configuration"),
        );
        return;
      }

      toast.success(
        translate("overall_line_efficiency.line_configuration_updated"),
      );

      client.invalidateQueries({
        queryKey: ["user-production-lines"],
      });

      dispatch({ type: "update line", config: line_configuration, line_id });
    },
  });
};
