import { useState } from "react";
import { useTranslate } from "@/i18n/config";
import { XrangePointOptionsObject } from "highcharts/highstock";
import { FilterAltOutlined } from "@mui/icons-material";
import { Box, Button, Checkbox, FormControlLabel } from "@mui/material";
import { Card } from "@/components/Layout/Card";
import {
  EVALUATION_COLORS,
  MAX_LAPS_NUMBER,
  useAcoposContext,
  useAcoposDispatchContext,
} from "../../context/acopos-context";
import { Evaluation, Lap, evaluation } from "../../types";
import { XRangeChart } from "@/components/highcharts/xrange/XRange";
import { TimeHelpers } from "@/utils/TimeHelpers";
import { useDisclosure } from "@/hooks/useDisclosure";
import { Modal } from "@/components/Modal";
import { toast } from "react-hot-toast";
import { z } from "zod";
import { LapSelectionTracker } from "./LapSelectionTracker";

const MAX_LAPS_CHART = 5;

const parseData = ({
  data,
  selectedLaps,
  selectedRun,
  filterBy,
}: {
  data: Lap[];
  selectedLaps: { [key: string]: Lap[] };
  selectedRun: string | null;
  filterBy: Evaluation | "all";
}): XrangePointOptionsObject[] => {
  if (!selectedRun) return [];
  if (Object.keys(selectedLaps).length === 0) return [];

  const filteredData =
    filterBy === "all"
      ? data
      : data.filter((entry) => entry.evaluation === filterBy);

  return filteredData.map((occurrence) => {
    const { name, start, end, evaluation } = occurrence;
    const isSelected = selectedLaps[selectedRun].find(
      (lap) => `${lap.start}-${lap.end}` === `${start}-${end}`,
    );
    //? fare color in base a evaluation
    const color = isSelected
      ? EVALUATION_COLORS[evaluation].selected
      : EVALUATION_COLORS[evaluation].notSelected;

    return {
      x: start,
      x2: end,
      y: 0,
      color,
      name: name,
    };
  });
};

export const SelectLaps = ({ runsLaps }: { runsLaps: Lap[] }) => {
  const translate = useTranslate();
  const [visibleMin, setVisibleMin] = useState<number | undefined>(undefined);
  const [visibleMax, setVisibleMax] = useState<number | undefined>(undefined);
  const [filterBy, setFilterBy] = useState<Evaluation | "all">("all");
  const { laps, isLapsLimitActive, selectedRun } = useAcoposContext();
  const dispatch = useAcoposDispatchContext();
  const { open, close, isOpen } = useDisclosure();
  const data = parseData({
    data: runsLaps,
    selectedLaps: laps,
    selectedRun,
    filterBy,
  });

  if (runsLaps.length === 0)
    return <Card>{translate("user_feedback.no_data_to_display")}</Card>;

  const xAxisMax =
    runsLaps.length > MAX_LAPS_CHART
      ? runsLaps[MAX_LAPS_CHART - 1].end
      : undefined;

  const onChangeDateTimeRange = ({
    start,
    end,
  }: {
    start: number;
    end: number;
  }) => {
    const clonedData: Lap[] = runsLaps.map((entry) => {
      return { ...entry };
    });
    const filteredLaps = clonedData.filter(
      (lap) => lap.start >= start && lap.end <= end,
    );

    const clonedStoreData: Lap[] = selectedRun
      ? laps[selectedRun].map((entry) => {
          return { ...entry };
        })
      : [];
    filteredLaps.forEach((lap) => {
      const lapIndex = clonedStoreData.findIndex(
        (obj) => `${obj.start}-${obj.end}` === `${lap.start}-${lap.end}`,
      );
      if (lapIndex > -1) {
        clonedStoreData.splice(lapIndex, 1);
      } else {
        clonedStoreData.push(lap);
      }
    });

    if (isLapsLimitActive && clonedStoreData.length > MAX_LAPS_NUMBER) {
      toast.error(
        translate("user_feedback.max_n_selected", { max: MAX_LAPS_NUMBER }),
      );
    }
    dispatch({ type: "TOGGLE_LAPS", item: filteredLaps });
  };

  const onClickLap = ({ start, end }: { start: number; end: number }) => {
    const clonedData: Lap[] = runsLaps.map((entry) => {
      return { ...entry };
    });
    const selectedLap = clonedData.find(
      (lap) => lap.start === start && lap.end === end,
    );

    if (selectedLap && selectedRun) {
      const isAlreadySelected =
        laps[selectedRun].findIndex(
          (lap) =>
            `${lap.start}-${lap.end}` ===
            `${selectedLap.start}-${selectedLap.end}`,
        ) > -1;

      if (isAlreadySelected) {
        dispatch({ type: "REMOVE_LAP", item: selectedLap });
      } else {
        if (isLapsLimitActive && laps[selectedRun].length >= MAX_LAPS_NUMBER) {
          toast.error(
            translate("user_feedback.max_n_selected", { max: MAX_LAPS_NUMBER }),
          );
        } else {
          dispatch({ type: "ADD_LAP", item: selectedLap });
        }
      }
    }
  };

  //! PER IL MOMENTO IN STAND-BY
  // const handleConfirm = () => {
  //   console.log("confirm");
  //   //? far partire chiamate
  //   //* mutation
  // };

  return (
    <>
      {isOpen && (
        <Modal
          open={isOpen}
          titleContent={translate("actions.show")}
          fullWidth
          maxWidth="lg"
          bodyContent={
            <Box sx={{ display: "flex", justifyContent: "center", gap: 1 }}>
              <Button
                variant="outlined"
                sx={{ width: 120 }}
                onClick={() => {
                  setFilterBy("all");
                  close();
                }}
              >
                {translate("all")}
              </Button>
              {Object.values(evaluation.Values).map((value) => (
                <Button
                  key={value}
                  sx={{ width: 120 }}
                  variant="outlined"
                  onClick={() => {
                    setFilterBy(value);
                    close();
                  }}
                >
                  {translate(`evaluation.${value}`)}
                </Button>
              ))}
            </Box>
          }
          actions={
            <Button variant="outlined" onClick={close}>
              {translate("actions.cancel")}
            </Button>
          }
        />
      )}

      <Box display="flex" flexDirection="column">
        <Box display="flex" justifyContent="space-between">
          <Box sx={{ p: ".5rem", display: "flex", gap: 1 }}>
            <Button variant="outlined" onClick={() => open()}>
              <FilterAltOutlined />
            </Button>
            {!isLapsLimitActive && (
              <Button
                variant="outlined"
                onClick={() => {
                  if (filterBy === "all") {
                    dispatch({ type: "UPDATE_LAPS", item: runsLaps });
                  } else {
                    dispatch({
                      type: "UPDATE_LAPS",
                      item: runsLaps.filter(
                        (entry) => entry.evaluation === filterBy,
                      ),
                    });
                  }
                }}
              >
                {translate("actions.select_all")}
              </Button>
            )}
            <Button
              variant="outlined"
              onClick={() => dispatch({ type: "CLEAR_LAPS" })}
            >
              {translate("actions.clear_all")}
            </Button>
            {/* PER IL MOMENTO IN STAND-BY */}
            {/* <Button variant="outlined" onClick={handleConfirm}>
              {translate("actions.confirm")}
            </Button> */}
          </Box>
          <FormControlLabel
            control={
              <Checkbox
                checked={!isLapsLimitActive}
                onChange={(_, val) =>
                  dispatch({ type: "SET_IS_LAPS_LIMIT_ACTIVE", item: !val })
                }
              />
            }
            label={translate("custom_charts.aggregated")}
          />
        </Box>
        <Box sx={{ height: 160 }}>
          <LapSelectionTracker
            totalLaps={runsLaps}
            minVisible={visibleMin}
            maxVisible={visibleMax}
          />
          <XRangeChart.Custom
            customHeight={150}
            allowExporting={false}
            categories={[translate("acopos.lap")]}
            xAxisOptions={{
              scrollbar: {
                enabled: data.length > MAX_LAPS_CHART ? true : false,
                liveRedraw: true,
                barBackgroundColor: "#0D1626",
                barBorderRadius: 7,
                barBorderWidth: 0,
                buttonBackgroundColor: "#0D1626",
                buttonBorderWidth: 1,
                buttonBorderRadius: 7,
                buttonArrowColor: "#0D1626",
                buttonBorderColor: "#3f4961",
                rifleColor: "#0D1626",
                trackBackgroundColor: "#3f4961",
                trackBorderWidth: 1,
                trackBorderRadius: 8,
                trackBorderColor: "none",
              },
              max: xAxisMax,
            }}
            // title="Seleziona i lap che vuoi monitorare"
            title=""
            data={data}
            tooltipFormatter={function () {
              const parseResult = z
                .object({
                  x: z.number(),
                  x2: z.number(),
                  color: z.string(),
                  key: z.string(),
                })
                .safeParse(this);
              if (!parseResult.success) return;

              const { color, key, x, x2 } = parseResult.data;

              const dateStart = TimeHelpers.parseTimestampToString({
                timestamp: x,
              });
              const dateEnd = TimeHelpers.parseTimestampToString({
                timestamp: x2,
              });
              const category = key;
              return `${translate("start")}: ${dateStart}<br>
                    ${translate("end")}: ${dateEnd}<br>
                    <span style="color:${color}">●</span> <b>${category}</b>
                  `;
            }}
            seriesOptions={{
              cursor: "pointer",
              events: {
                click: function (event) {
                  const point: XrangePointOptionsObject = event.point;
                  onClickLap({ start: point.x!, end: point.x2! });
                },
              },
            }}
            chartOptions={{
              events: {
                selection: function (event) {
                  const start = event.xAxis[0].min;
                  const end = event.xAxis[0].max;
                  onChangeDateTimeRange({ start, end });
                  return false;
                },
                render: function () {
                  if (this.series[0]) {
                    const min = this.series[0].chart.axes[0].min;
                    const max = this.series[0].chart.axes[0].max;
                    if (min && max) {
                      setVisibleMin(min);
                      setVisibleMax(max);
                    }
                  }
                },
              },
            }}
          />
        </Box>
      </Box>
    </>
  );
};
