import "reactflow/dist/style.css";
import { LineConfig } from "../Pages/Edit/types";
import { Box } from "@mui/material";
import {
  ReactFlow,
  ReactFlowProvider,
  Controls,
  Background,
  BackgroundVariant,
} from "reactflow";
import {
  LineNodeTypes,
  HistoricLineNodeTypes,
} from "../Pages/Edit/custom-nodes/LineNode";

export const PlantDesign = ({
  config,
  historicNodes = false,
}: {
  config: LineConfig;
  historicNodes?: boolean;
}) => {
  return (
    <Box
      sx={{
        height: 600,
      }}
    >
      <ReactFlowProvider>
        <ReactFlow
          defaultEdgeOptions={{
            type: "smoothstep",
            animated: true,
          }}
          nodeTypes={historicNodes ? HistoricLineNodeTypes : LineNodeTypes}
          fitView
          proOptions={{
            hideAttribution: true,
          }}
          snapToGrid
          style={{
            backgroundColor: "rgba(255, 255, 255, .1)",
          }}
          edges={config.edges}
          nodes={config.nodes}
        >
          <Controls showInteractive={false} />
          <Background
            lineWidth={1}
            color="#0000000f"
            variant={BackgroundVariant.Lines}
          />
        </ReactFlow>
      </ReactFlowProvider>
    </Box>
  );
};
