import { useTranslate } from "@/i18n/config";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { useGetSingleImputationStates } from "../api/useGetSingleImputationStates";
import { SkeletonCard } from "@/components/Layout/SkeletonCard";
import { XRangeChart } from "@/components/highcharts/xrange/XRange";
import { Card } from "@/components/Layout/Card";
import { parseStatesLogData } from "../utils/parseStatesLogData";

export const ImputationStatesModal = ({
  dateStart,
  dateEnd,
  isOpen,
  close,
}: {
  dateStart: number;
  dateEnd: number;
  isOpen: boolean;
  close: () => void;
}) => {
  const translate = useTranslate();
  const { data, isLoading, error } = useGetSingleImputationStates({
    dateStart,
    dateEnd,
  });

  if (isLoading)
    return (
      <Dialog onClose={close} open={isOpen} maxWidth="lg" fullWidth>
        <DialogTitle>
          {translate("stop_imputation.machine_states_log_by_imputation")}
        </DialogTitle>
        <DialogContent>
          <SkeletonCard height={400} />
        </DialogContent>
      </Dialog>
    );

  if (error) return <div>{translate("user_feedback.an_error_occurred")}</div>;

  if (!data) return <div>{translate("user_feedback.no_data")}</div>;

  const { categories, chartData } = parseStatesLogData(data.data);

  return (
    <Dialog onClose={close} open={isOpen} maxWidth="lg" fullWidth>
      <DialogTitle>
        {translate("stop_imputation.machine_states_log_by_imputation")}
      </DialogTitle>
      <DialogContent>
        <Card>
          <XRangeChart.Custom
            categories={categories}
            data={chartData}
            title=""
          />
        </Card>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={close}>
          {translate("actions.back")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
